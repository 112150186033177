import types from '../types/budgetActionTypes';

export const setBudgetData = (payload) => {
  return { type: types.SET_BUDGET_DATA, payload }
}

export const setInputValue = (name, value) => {
  return { type: types.SET_INPUT_VALUE, payload: { name, value } }
}

export const setBudgetDetail = (payload) => {
  return { type: types.SET_BUDGET_DETAIL, payload }
}

export const resetBudgetDetail = (payload) => {
  return { type: types.RESET_BUDGET_DETAIL, payload }
}

export const setVersionDetails = (payload) => {
  return { type: types.SET_VERSION_DETAILS, payload }
}
