const types = {
  SET_BUDGET_DATA: 'SET_BUDGET_DATA',
  ADD_NEW_BUDGET: 'ADD_NEW_BUDGET',
  SET_INPUT_VALUE: 'SET_INPUT_VALUE',
  SET_BUDGET_DETAIL: 'SET_BUDGET_DETAIL',
  RESET_BUDGET_DETAIL: 'RESET_BUDGET_DETAIL',
  SET_VERSION_DETAILS: "SET_VERSION_DETAILS"
}

export default types;
