import {
  Button,
  ButtonGroup,
  Modal,
  Spinner
} from "@walmart-web/livingdesign-components";
import React from "react";
import { createUseStyles } from "react-jss";
import { CANCEL } from "../../../constants/actionButtons";
import { BUDGET_LABELS } from "../../../constants/constants";
import { EXISTING_BUDGET_ALERT_MESSAGE } from "../../../constants/messages";
import { existingBudgetAlertMsg } from "../../../constants/templates";

const SubmitActions = (props) => {
  const { submitButtonText, onSubmit, onCancel, submitLoading } = props;

  return (
    <ButtonGroup>
      <Button
        data-testid="cancel-btn"
        id="cancel-btn"
        size="medium"
        onClick={onCancel}
        disabled={submitLoading}
      >
        {CANCEL}
      </Button>
      <Button
        variant="primary"
        size="medium"
        data-testid="submit-btn"
        id="submit-btn"
        onClick={onSubmit}
        disabled={submitLoading}
      >
        {submitLoading ? (
          <Spinner color="white" size="small" />
        ) : (
          submitButtonText
        )}
      </Button>
    </ButtonGroup>
  );
};

const SubmitBudgetModal = (props) => {
  const useStyles = createUseStyles({
    textStyle: {
      fontSize: 18,
      fontWeight: 400,
      letterSpacing: 0,
      textAlign: 'left',
      whiteSpace: "break-spaces"
    },
  });
  const {
    open,
    setOpen,
    title,
    submitButtonText,
    onSubmit,
    submitLoading,
    budgetData,
    onCloseModal
  } = props;
	const classes = useStyles();

  let timePeriod;
  if (budgetData?.[0]?.timePeriod === BUDGET_LABELS.LIFE_OF_MATTER) {
    timePeriod = BUDGET_LABELS.LOM
  } else {
    timePeriod = `FY${new Date(budgetData?.[0]?.endDate).getFullYear()}`
  }

  /*
  * On Close Modal- Reset the version Details
  */
  const onCancel = () => {
    setOpen(false)
    onCloseModal()
  }

  return (
    <Modal
      data-testid="submit-Modal"
      actions={
        <SubmitActions
          submitButtonText={submitButtonText}
          onSubmit={onSubmit}
          onCancel={onCancel}
          submitLoading={submitLoading}
        />
      }
      isOpen={open}
      onClose={onCancel}
      size="medium"
      title={title}>
      <div className={classes.textStyle}>{existingBudgetAlertMsg(budgetData?.[0]?.type, timePeriod, budgetData?.[0]?.status)}</div>
      <br />
      <div className={classes.textStyle}>{EXISTING_BUDGET_ALERT_MESSAGE}</div>
    </Modal>
  );
};

export default SubmitBudgetModal;
