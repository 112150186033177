export const CREATE = "Create";
export const UPDATE = "Update";
export const SUBMIT = "Submit";
export const RESUBMIT = "Resubmit";
export const DONE = "Done";
export const CANCEL = "Cancel";
export const CLEAR = "Clear";
export const REJECT = "Reject";
export const REMOVE = "Remove";
export const DISCARD = "Discard";
export const MORE = "More";
export const PROCEED = "Proceed";
export const SAVE = "Save";
export const VALIDATE_AND_SUBMIT = "Validate & Submit";
export const OK = "Ok";
export const DOWNLOAD_ERROR_DETAILS = "Download error details";
export const NEXT = "Next";
export const GO_BACK = "Go Back";
export const CONFIRM = "Confirm";
export const CONTINUE = "Continue";
export const EXPORT = "Export";
export const FILTERS = 'Filters';
export const SHARE = 'Share';
export const DOWNLOAD = 'Download';
export const DELETE = "Delete";
export const DEACTIVATE = 'Deactivate';
export const ADD = 'Add';
export const EDIT = 'Edit';
export const RETRY = "Retry";
export const REFRESH = "Refresh";
export const LOGOUT = "Log Out";
export const SIGN_IN = "Sign In";
export const REQUEST_ACCESS = "Request Access";
export const RESET_PASSWORD = "Reset Password";
export const CLEAR_ALL_FILTERS = 'Clear All Filters';
export const EXPAND_ALL = "Expand all";
export const COLLAPSE_ALL = "Collapse all";
export const SUBMIT_REQUEST="Submit Request";
export const RESTORE = "Restore"
