import { createUseStyles } from "react-jss";
import {
  Alert, Breadcrumb,
  BreadcrumbItem, Button, ButtonGroup, Caption,
  Grid,
  GridColumn,
  Heading, Modal, TabNavigation,
  TabNavigationItem, Tag, useSnackbar} from "@walmart-web/livingdesign-components";
import { Check, Close } from "@walmart-web/livingdesign-icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { getService } from "../../../axios/axios";
import { LIGHT_GRAY, LIGHT_GREEN, LIGHT_RED, LIGHT_YELLOW } from "../../../constants/colors";
import {
  CREATE_NEW_VERSION,
  REJECTED,
  budgetStatusTagColors,
  SUBMITTED,
  TYPE,
  START,
  END,
  BUDGET_LABELS,
  SPEND
} from "../../../constants/constants";
import { CONFIRM, GO_BACK, EDIT } from "../../../constants/actionButtons";
import BudgetDetails from "../Budget/BudgetDetails/BudgetDetails";
import BudgetTimeline from "../Budget/BudgetTimeline/BudgetTimeline";
import OverviewPhase from "../Budget/BudgetOverview/OverviewPhase/OverviewPhase";
import OverviewTotal from "../Budget/BudgetOverview/OverviewTotal/OverviewTotal";
import { resetBudgetDetail, setBudgetDetail } from "../../../store/actions/budgetActions";
import BudgetInvoiceList from "../Budget/BudgetInvoiceList/BudgetInvoiceList";
import { BUDGET_SERVICE } from "../../../constants/baseURLs";
import { isValueEmptyCheck } from "../../../helpers/validationUtils";
import { getErrorMessage, renderTernary } from "../../../helpers/utils";
import { displayLocalDate } from "../../../helpers/utcUtils";
import LoadComponent from "../../../components/LoadComponent/LoadComponent";
import moment from "moment";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";

const items = ["Overview", "Details", "Invoices", "Timeline"];
const showEditBtnStatusList = ["approved"];

const useStyles = createUseStyles({
  listing: {
    backgroundColor: LIGHT_GRAY,
    padding: "25px 30px",
    flex: 1,
    position: "relative",
  },
  percentChip: {
    width: '60px',
    height: '20px',
    display: 'block',
    textAlign: 'center',
    borderRadius: '10px',
    background: '#E3E4E5',
    overflow: 'hidden',
    marginTop:"4px",
    "& div>span": {
      marginTop: "-4px",
      fontSize:"18px"
    },
  },
  wd100:{
    display: 'block',
    textAlign: 'left',
    marginRight: '10px',
    width: "162px",
    "&:first-child": {
      marginLeft: "10px"
    }
  },
  pageTitle: {
    fontSize: 36,
    fontWeight: "bold",
    lineHeight: "1.33",
    display: "block",
  },
  pageInfoBold: {
    fontWeight: "bold",
    fontSize: "20px",
  },
  label: {
    fontSize: "14px",
  },
  header: {
    paddingLeft: 16,
  },
  tabContainer: {
    backgroundColor: "#ffffff",
    padding: "0px",
    margin: "24px 0px 24px 0px",
    "& nav ": {
      backgroundColor: "#f5f5f5",
    },
    "& nav >ul>li>a": {
      backgroundColor: "#f5f5f5",
    },
  },
  breadcrumb: {
    marginBottom: "26px",
  },
  gridLayout: {
    display: "grid",
    gap: '15px',
    gridTemplateColumns: 'repeat(auto-fit, minmax(150px, 1fr))',
    margin: '16px 10px'
  },
  icons: {
    display: "flex",
    justifyContent: "right",
    "& button": {
      marginRight: "8px"
    },
  },
  pageTitleGroup: {
    marginTop: "16px"
  },
  pageTitleWrap: {
    display: "flex",
    alignItems: 'center'
  },
  pageTitleBadge: {
    padding: "2px 8px",
    backgroundColor: "#f1f1f2",
    height: "24px",
    borderRadius: "2px",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#515357",
    marginLeft: "13px",
    marginTop: "8px"
  },
  loadingStatus: {
    padding: "2px 8px",
    height: "24px",
    borderRadius: "2px",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#515357",
    marginLeft: "13px",
    marginTop: "10px",
    width: "100px",
    display: "inline-block"
  },
  overviewInnerWraper: {
    padding: "16px",
  },
  alertStyle: {
    paddingTop: "24px"
  },
  goBackBanner: {
    alignItems: "center",
    background: "#002D58",
    display: "flex",
    justifyContent: "space-between",
    left: "0",
    padding: "26px 24px",
    position: "absolute",
    top: "0",
    width: `calc(100% - 52px)`,
  },
  goBackBannerTitle: {
    color: "#fff",
    fontSize: "20px",
  },
  addTopPadding: {
    paddingTop: "116px"
  },
  disableTabs: {
    opacity: 0.5,
    pointerEvents: "none"
  }
});

/**
 * Actions for Edit Confirm Modal
 **/
const EditConfirmModalActions = (props) => {
  const history = useHistory();
  const {setEditConfirmModalOpen, budgetId} = props
  return (
    <Grid>
      <GridColumn sm={5}>
        <ButtonGroup>
          <Button
            data-testid="goback-btn"
            onClick={() => setEditConfirmModalOpen(false)}
          >
            {GO_BACK}
          </Button>
          <Button
            variant="primary"
            data-testid="confirm-btn"
            onClick={() => {
              history.push(`/budget/${budgetId}/view`, { budgetId: budgetId });
              setEditConfirmModalOpen(false);
            }}
          >
            {CONFIRM}
          </Button>
        </ButtonGroup>
      </GridColumn>
    </Grid>
  );
};

const ViewBudget = () => {
  const classes = useStyles();
  const { state: { budgetType, fromMatter, currentMatterTab, showWorkFlow, currentFinancialTab } = {} } = useLocation();
  const { budgetDetail } = useSelector(state => state?.budget);
  const [detailBudget, setDetailBudget] = useState({});
  const dispatch = useDispatch();
  const { budgetId } = useParams();
  const { addSnack } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [rejectionComment, setRejectionComment] = useState('');

  //showWorkFlow prop set to be true, if workflow is expected in tabs
  if (showWorkFlow === true && items?.indexOf('Workflow') === -1) {
    items?.splice(items?.indexOf('Timeline'), 0, 'Workflow');
  }

  const readBudgetDetails = () => {
    getService(BUDGET_SERVICE, `/budgets/v1/${budgetId}`)
      .then((res) => {
        const detail = res?.data;
        const submitted = displayLocalDate(detail?.createdTs)
        const lastEdited = `${displayLocalDate(detail?.lastUpdatedTs)} by ${detail?.lastUpdatedBy}`
        setDetailBudget({ ...detail, lastEdited, submitted });
        dispatch(setBudgetDetail({ ...detail, lastEdited, submitted }));
        setRejectionComment(detail?.comments[0]);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        addSnack({
          message: getErrorMessage(error)
        });
      });
  }

  useEffect(() => {
    window.scrollTo({ top: 0, behaviour: 'smooth' })
    setLoading(true);
    readBudgetDetails();
    return () => {
      dispatch(resetBudgetDetail());
    }
  }, [budgetId]);

  useEffect(() => {
    setDetailBudget(budgetDetail);
  }, [budgetDetail])

  return (
    <div className={`${classes.listing}`}>
      <HeaderSection
        fromMatter={fromMatter}
        currentMatterTab={currentMatterTab}
        loading={loading}
        budgetDetail={detailBudget} currentFinancialTab={currentFinancialTab} readBudgetDetails={readBudgetDetails} rejectionComment={rejectionComment} />
      <TabsLayout budgetType={budgetType} fromMatter={fromMatter} budgetDetail={detailBudget} loading={loading} />
    </div>
  );
};

const HeaderSection = ({ fromMatter, currentMatterTab, budgetDetail, loading, currentFinancialTab, readBudgetDetails, rejectionComment }) => {
  const classes = useStyles();
  const history = useHistory();
  const { budgetId } = useParams();
  const [editConfirmModalOpen, setEditConfirmModalOpen] = useState(false);
  const budgetDetailsActionsAccessibility = useSelector((state) => state?.accessibility?.applicationAccess?.financial?.budget?.details?.actions);
  const loadComponentProps = {
    loader: loading,
    skeletonHeight: '20px',
    skeletonWidth: '100%',
    skeletonVariant: 'reactangle',
    skeletonLines: 1
  };
  /**
   * calls this function when we edit the budget.
   **/
  const handleEdit = () => {
    const expired = false;
    if (expired) {
      setEditConfirmModalOpen(true);
    } else {
      history.push(`/budget/${budgetId}/view`, { budgetId: budgetId });
    }
  };

  const renderPaidPercentage=() => {
    const paidAm = budgetDetail?.paidAmount;
    const totalAm = budgetDetail?.budgetAmount;
    let vl = '0';
    if (paidAm !== undefined && totalAm !== undefined && paidAm !== 0 && totalAm !== 0) {
      vl = ((paidAm) * 100) / totalAm;
    }
    let barColor = LIGHT_RED;
    if (vl < 30) {
      barColor = LIGHT_GREEN;
    } else if (vl >= 30 && vl < 70) {
      barColor = LIGHT_YELLOW;
    }
    return (
      <div className={`${classes.percentChip}`}>
        <ProgressBar bgColor={barColor} completed={parseInt(vl)} />
      </div>
    )
  }

  return (
    <div>
      <Modal
        onClose={() => setEditConfirmModalOpen(false)}
        isOpen={editConfirmModalOpen}
        actions={<EditConfirmModalActions setEditConfirmModalOpen={setEditConfirmModalOpen} budgetId={budgetId}/>}
        title={CREATE_NEW_VERSION}
        data-testid="edit-confirm-modal"
      >
        Editing this expired budget will require approval from <strong>Tort Default Workflow</strong>
      </Modal>
      <Grid>
        <GridColumn sm={11}>
          {fromMatter ? (
            <Breadcrumb>
              <BreadcrumbItem data-testid="budget-nav-matter" onClick={() => history.push(`/`)}>Matters</BreadcrumbItem>
              <BreadcrumbItem
                data-testid="budget-nav-matter-detail"
                onClick={() => history.push(`/matter/${budgetDetail?.matterId}`)}>
                {budgetDetail?.matterNumber}
              </BreadcrumbItem>
              <BreadcrumbItem
                data-testid="budget-nav-matter-budget-list"
                onClick={() => history.push(`/matter/${budgetDetail?.matterId}`, { currentMatterTab: currentMatterTab, currentFinancialTab: 0 })}>
                Financials
              </BreadcrumbItem>
              <BreadcrumbItem isCurrent data-testid="budget-nav-matter-version">
                <LoadComponent {...loadComponentProps }>
                  <div id={`budget-name-version`}>
                    {renderTernary(budgetDetail?.budgetName && budgetDetail?.version, `${budgetDetail?.budgetName}`, '-')}
                  </div>
                </LoadComponent>
              </BreadcrumbItem>
            </Breadcrumb>
          ) : (
            <Breadcrumb>
              <BreadcrumbItem data-testid="budget-nav-financial" onClick={() => history.push(`/financial`, { currentFinancialTab })}>Financials</BreadcrumbItem>
              <BreadcrumbItem isCurrent>
                <LoadComponent {...loadComponentProps }>
                  <div id={`budget-name-version`}>
                      {renderTernary(budgetDetail?.budgetName && budgetDetail?.version, `${budgetDetail?.budgetName}`, '-')}
                  </div>
                </LoadComponent>
              </BreadcrumbItem>
            </Breadcrumb>
          )}
        </GridColumn>
        <GridColumn sm={1}>
          {!loading && (
            <div className={classes.icons}>
              {renderTernary(budgetDetailsActionsAccessibility?.edit,
                showEditBtnStatusList.includes(budgetDetail?.status?.toLowerCase()) &&
                  <Button size="small" variant="tertiary" onClick={handleEdit} data-testid="btn-edit" id="edit-btn">
                    {EDIT}
                  </Button>,
                <React.Fragment></React.Fragment>
                )}
            </div>
          )}
        </GridColumn>
      </Grid>

      {((budgetDetail?.status?.toUpperCase() === REJECTED) && loading === false) ?
        <div className={classes.alertStyle}>
          <Alert variant="error">
            <div>
              <span style={{ fontWeight: "bold" }}>Budget Rejected </span>:
            </div>
            <div>
              {rejectionComment?.comment}
            </div>
          </Alert>
        </div> : ""
      }

      <div className={classes.gridLayout}>
        <div>
          <Heading as="h1" size="large" className={classes.pageTitle}>
            <LoadComponent {...loadComponentProps }>
              <span id="budget-view-budget-name">{budgetDetail?.budgetName || "-"}</span>
              <span className={classes.pageTitleBadge} >{(budgetDetail?.status?.toLowerCase() === "rejected") ?
                <Tag id="rejected-tag"
                  color="red"
                  leading={<Close />}
                  variant="primary"
                >
                  Rejected
                </Tag>
                : <Tag id= {budgetDetail?.status?.toLowerCase() === 'approved'?"approved-tag":"pending-tag"}
                color={budgetStatusTagColors[budgetDetail?.status?.toLowerCase()]?.color || "gray"}
                variant={budgetStatusTagColors[budgetDetail?.status?.toLowerCase()]?.variant || "secondary"}
                leading={budgetDetail?.status?.toLowerCase() === 'approved' ? <Check /> : null}
                >
                  {budgetStatusTagColors[budgetDetail?.status?.toLowerCase()]?.label}
                </Tag>}
              </span>
            </LoadComponent>
          </Heading>
        </div>
      </div>

      <div className={classes.gridLayout}>
          <div>
            <div className={classes.label}>
              <Caption as="span" size="large">
              {BUDGET_LABELS.FISCAL_YEAR}
              </Caption>
            </div>
            <div className={classes.pageInfoBold}>
              <Heading as="span" size="h3">
                <LoadComponent {...loadComponentProps }>
                {renderTernary((budgetDetail?.timePeriod === BUDGET_LABELS.LIFE_OF_MATTER), BUDGET_LABELS.LOM, `FY${moment(budgetDetail?.endDate).year()}`)}
                </LoadComponent>
              </Heading>
            </div>
          </div>
          <div>
            <div className={classes.label}>
              <Caption as="span" size="large">
                {TYPE}
              </Caption>
            </div>
            <div className={classes.pageInfoBold}>
              <Heading as="span" size="h3" id="budget-view-type">
                <LoadComponent {...loadComponentProps }>
                  {isValueEmptyCheck(budgetDetail?.type)}
                </LoadComponent>
              </Heading>
            </div>
          </div>
          <div>
            <div className={classes.label}>
              <Caption as="span" size="large">
                %{SPEND}
              </Caption>
            </div>
            <div className={classes.pageInfoBold}>
              <Heading as="span" size="h3">
                <LoadComponent {...loadComponentProps }>
                {renderPaidPercentage()}
                </LoadComponent>
              </Heading>
            </div>
          </div>
          <div>
            <div className={classes.label}>
              <Caption as="span" size="large">
                {SUBMITTED}
              </Caption>
            </div>
            <div className={classes.pageInfoBold}>
              <Heading as="span" size="h3" id="budget-view-submitted-date">
                <LoadComponent {...loadComponentProps }>
                  {isValueEmptyCheck(budgetDetail?.submitted)}
                </LoadComponent>
              </Heading>
            </div>
          </div>
          <div>
            <div className={classes.label}>
              <Caption as="span" size="large">
                {START}
              </Caption>
            </div>
            <div className={classes.pageInfoBold}>
              <Heading as="span" size="h3">
                <LoadComponent {...loadComponentProps }>
                  {renderTernary(budgetDetail?.startDate, displayLocalDate(budgetDetail?.startDate), '-')}
                </LoadComponent>
              </Heading>
            </div>
          </div>
          <div>
            <div className={classes.label}>
              <Caption as="span" size="large">
                {END}
              </Caption>
            </div>
            <div className={classes.pageInfoBold}>
              <Heading as="span" size="h3">
                <LoadComponent {...loadComponentProps }>
                  {renderTernary(budgetDetail?.endDate, displayLocalDate(budgetDetail?.endDate), '-')}
                </LoadComponent>
              </Heading>
            </div>
          </div>
        </div>
    </div>
  );
};


const TabsLayout = ({ budgetType, fromMatter, budgetDetail,loading }) => {
  const [isCurrent, setIsCurrent] = useState("Overview");
  const classes = useStyles();

  return (
    <div className={classes.tabContainer}>
      <TabNavigation>
        {items?.map((item, index) => (
          <TabNavigationItem
            onClick={() => {
              setIsCurrent(item);
            }}
            isCurrent={isCurrent === item}
            key={`${item}`}
            data-testid={item?.split(" ").join("-")}
            id={item?.split(" ").join("-")}
            UNSAFE_className={loading ? classes.disableTabs: ""}
          >
            {item}
          </TabNavigationItem>
        ))}
      </TabNavigation>
      {TabContent(isCurrent,fromMatter, budgetDetail, setIsCurrent)}
    </div>
  );
};


const TabContent = (isCurrent, fromMatter, budgetDetail, setIsCurrent) => {
  const classes = useStyles();

  switch (isCurrent) {
    case 'Overview':
      return (
        <div className={classes.overviewInnerWraper}>
          {budgetDetail?.type && budgetDetail?.type?.toLowerCase() === "phase" && <OverviewPhase />}
          {budgetDetail?.type && budgetDetail?.type?.toLowerCase() === "total" && <OverviewTotal />}
        </div>
      );
    case 'Details':
      return (
        <BudgetDetails budgetDetail={budgetDetail} />
      );
    case 'Invoices':
      return (
        <BudgetInvoiceList fromMatter={fromMatter} budgetDetail={budgetDetail}/>
      );
    case 'Timeline':
      return (
        <div className={classes.overviewInnerWraper}>
          <BudgetTimeline setIsCurrent={setIsCurrent} fromMatter={fromMatter}/>
        </div>
      );
    case 'Workflow':
      return (
        <></>
      )
    default:
      return null;
  }
};

export default ViewBudget;
