import React, { useEffect, useState } from "react";
import {
  TextField,
  Autocomplete,
  Box,
  createFilterOptions,
} from "@mui/material";
import {
  detailModes as modes,
} from "../../../constants/constants";

const UtbmsActivityAutocomplete = (props) => {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const {
    name,
    value,
    setValue,
    label,
    placeholder,
    helperText,
    data,
    handleBlur,
    tableMeta,
    showDescription = true,
    codeId = "",
    selectedValues = [],
    fromBudget = false,
    mode
  } = props;

  const set = new Set;
  let optionsData = data?.filter(o => !set.has(o?.['value']) && set.add(o?.['value']))

  /*
* This array contains items which are not selected earlier.
*/
  if (fromBudget) {
    let selectedOptions;
    if (mode === modes.CREATE) {
      selectedOptions = selectedValues?.map(item => JSON.parse(item?.code))
      optionsData = optionsData.filter(
        item2 => !selectedOptions.some(item1 => item1?.value === item2?.value)
      );
    } else {
      const withNewKey = [];
      const withoutNewKey = [];
      selectedValues?.forEach(item => {
        if ('new' in item) {
          const parsedData = JSON.parse(item?.code)
          withNewKey.push(parsedData?.value);
        } else {
          withoutNewKey.push(item?.code);
        }
      });
      const selectedCodes = [...withoutNewKey, ...withNewKey]
      optionsData = optionsData?.filter(
        item2 => !selectedCodes?.some(item1 => item1 === item2?.value)
      );
    }
  }

  useEffect(() => {
    if(value !== '' && value !== undefined) {
      setSelectedOption(JSON.parse(value))
    }
  }, [value])

  const filterOptions = !showDescription ? createFilterOptions({
    stringify: (option) =>
      `${option?.value}`,
  }):createFilterOptions({
    stringify: (option) =>
      `${option?.value} (${option?.description})`,
});

  return (
    <Autocomplete
      open={open}
      onOpen={() => { setOpen(true); }}
      onClose={() => { setOpen(false); }}
      name={`${name}-autocomplete`}
      data-testid={`${name}-autocomplete`}
      id={`${name}-autocomplete`}
      autoHighlight
      autoComplete
      options={optionsData}
      getOptionLabel={(option) => {
        if(!showDescription)
        {
          return `${option?.value}`
        }
        else
        {
        return `${option?.value} (${option?.description})`
        }
      }}
      renderOption={(props, option) => {
        if(!showDescription)
        {
          return (<Box component="li" {...props}>{option?.value}</Box>)
        }
        else
        {
        return (<Box component="li" {...props}>{option?.value} ({option?.description})</Box>)
        }
      }}
      filterOptions={filterOptions}
      value={(value !== '' && value !== undefined)?JSON.parse(value):value}
      onChange={(e, v) => {
        setSelectedOption(v);
        if(codeId !== "")
        {
          setValue(JSON.stringify(v), codeId)
        }
        else
        {
          setValue(JSON.stringify(v))
        }
      }}
      onBlur={() => handleBlur(name, tableMeta, JSON.stringify(selectedOption))}
      renderInput={(params) => (
        <TextField
          {...params}
          inputProps={{ ...params?.inputProps }}
          margin="dense"
          label={label}
          placeholder={placeholder}
          // helperText={helperText}
          variant="outlined"
          error={Boolean(helperText)}
        />
      )}
    />
  );
};

export default UtbmsActivityAutocomplete;
