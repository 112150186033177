import { Body, Button, ButtonGroup, Grid, GridColumn, Modal, useSnackbar } from "@walmart-web/livingdesign-components";
import { Plus } from "@walmart-web/livingdesign-icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { patchService, postService } from "../../../../axios/axios";
import ActionPopover from "../../../../components/Table/ActionPopover/ActionPopover";
import MuiDataTable from "../../../../components/Table/MuiDataTable/MuiDataTable";
import { CANCEL, DEACTIVATE } from "../../../../constants/actionButtons";
import { COMPOSITE_SEARCH_SERVICE, EXTERNAL_USER_SERVICE } from "../../../../constants/baseURLs";
import {
  CS_DEFAULT_SORT_QUERY, CS_IS_ACTIVE_FILTER, CS_IS_EXTERNAL_USER_FILTER, CS_SCOPES,
  CS_SCORE_SORT_OBJ,
  DEACTIVATE_USER,
  EXPORT_MAX_LIMIT_CONF,
  PEOPLE,
  PEOPLE_DATA
} from "../../../../constants/constants";
import { USERS_DEACTIVATE_MSG } from "../../../../constants/messages";
import { ROWS_PER_PAGE_OPTIONS_PEOPLE, ROWS_PER_PAGE_PEOPLE, } from "../../../../constants/tableConfigs";
import { formCompositeSearchQuery, formCsFilterObject, formCsPaginationQuery, formCsSortQuery, formRawSearchQuery } from "../../../../helpers/csQueryUtils";
import { getAllowedActionsList, getExportedData } from "../../../../helpers/tableUtils";
import { getErrorMessage } from "../../../../helpers/utils";
import { setExternalUserData } from "../../../../store/actions/peopleAction";
import OrganizationDetailPeopleStyles from "./ActivePeople.styles";
import { COLUMNS_PEOPLE, FILTER_CONFIGS_PEOPLE } from "../OrganizationPeople";
import ExportModal from "../../../../components/Modal/ExportModal/ExportModal";

/**
* Component to return action popover on add new button
* @returns
*/
const AddNewActionPopover = ({ addNewActionItems, handleAddNew }) => {
  const classes = OrganizationDetailPeopleStyles();
  return (
    <div className={classes.addNewBtnWrap}>
      <ActionPopover
        actionItems={addNewActionItems}
        onClickOption={handleAddNew}
        data-testid="add-new-action-btn"
        buttonIcon={<Plus />}
        buttonText={"Add New"}
        style={{ marginLeft: "10px" }}
      />
    </div>
  )
}
/**
 * Make Deactivate external user Modal Actions
 */
const MakeDeactivateExternalUserModalActions = ({ setDeactivateExternalUserModalOpen, deactivateLoading, handleDeactivate, selectedRowData }) => {
  return (
    <Grid>
      <GridColumn sm={5}>
        <ButtonGroup>
          <Button
            data-testid="goback-btn"
            id="goback-btn"
            size="small"
            onClick={() => setDeactivateExternalUserModalOpen(false)}
            disabled={deactivateLoading}
          >
            {CANCEL}
          </Button>
          <Button
            variant="destructive"
            size="small"
            data-testid="continue-btn"
            id="continue-btn"
            onClick={() => handleDeactivate(selectedRowData)}
            disabled={deactivateLoading}
          >
            {DEACTIVATE}
          </Button>
        </ButtonGroup>
      </GridColumn>
    </Grid>
  );
};

const ActivePeople = (props) => {
  const { selectedTab } = props;
  const accessibility = useSelector((state) => state?.accessibility?.applicationAccess?.organization?.tabs?.people?.tabs?.active);
  const identifier = useSelector((state) => state?.organization?.organizationDetailValues?.identifier);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false)
  const [deactivateExternalUserModalOpen, setDeactivateExternalUserModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [deactivateLoading, setDeactivateLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [createExportLoading, setCreateExportLoading] = useState(false);
  const [selectRowsData, setSelectRowsData] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState({});
  const [filterQuery, setFilterQuery] = useState({});
  const [sortQuery, setSortQuery] = useState(CS_DEFAULT_SORT_QUERY);
  const [rowsPerPagePeople, setRowsPerPagePeople] = useState(ROWS_PER_PAGE_PEOPLE);
  const dispatch = useDispatch();
  const { addSnack } = useSnackbar();

  const renderAction = (value, tableMeta) => {
    const status = tableMeta?.rowData?.[6];
    if (status === "Active") {
      return <ActionPopover
        actionItems={[{
          "actionId": 0,
          "label": DEACTIVATE,
          "isAboveDivider": true,
          "showAction": accessibility?.deactivate
        }]}
        onClickOption={onClickOption}
        data-testid="action-btn"
        tableMeta={tableMeta}
      />
    }
  }

  const COLUMNS = [
    ...COLUMNS_PEOPLE,
    {
      name: "status",
      label: "Status",
      colKey: "status",
      options: {
        sort: false,
        display: false,
      }
    },
    {
      name: "actions",
      label: 'Actions',
      colKey: "actions",
      options: {
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            color: "#2E2F32",
            fontWeight: "bold",
            fontSize: "12px",
            fontFamily: "Bogle"
          },
        }),
        customBodyRender: renderAction,
      }
    },
  ];

  const [peopleColumns, setPeopleColumns] = useState(COLUMNS);
  const externalUserData = useSelector((state) => state?.people?.externalUserData);
  const { nodeList, count } = externalUserData
  const [externalUsersData, setExternalUsersData] = useState([]);
  const showExportAllOption = count < EXPORT_MAX_LIMIT_CONF.EXTERNAL_USER;
  const defaultExportOption = showExportAllOption ? 'all' + PEOPLE : 'currentPage';
  const [exportSelection, setExportSelection] = useState(defaultExportOption);
  const [currentPageExport, setCurrentPageExport] = useState(false);

  const history = useHistory();
  const classes = OrganizationDetailPeopleStyles();

  useEffect(() => {
    const data = nodeList?.filter(item => item?.userRequestId);
    setExternalUsersData(data);
  }, [nodeList])

  /**
  * Function to call api on pagination action (server side)
  * @param {number} rowsPerPage
  * @param {number} page */
  const handleServerSidePagination = ({ rowsPerPage, page }) => {
    setRowsPerPagePeople(rowsPerPage);
    setPage(page);
    const paginationQuery = formCsPaginationQuery(rowsPerPage, (rowsPerPage * page));
    getPeopleData(paginationQuery);
  };

  /**
  * Function to validate search query and set query or show no results UI (server side)
  * @param {string} searchText
  */
  const handleServerSideSearch = (searchText) => {
    const searchQuery = formRawSearchQuery(searchText);
    setSearchQuery(searchQuery);
  };
  /**
  *  Function to set filter query on filter action (server side)
  * @param {string} query
  */
  const handleServerSideFilter = (filterQuery) => {
    setFilterQuery(filterQuery);
  };

  /**
  * Function to call api on sort action (server side)
  * @param {number} rowsPerPage
  * @param {number} page
  */
  const handleServerSideSort = ({ name, direction }) => {
    const sortQuery = formCsSortQuery(name, direction);
    setSortQuery(sortQuery);
  };


  const peopleCustomOptions = {
    rowsPerPage: rowsPerPagePeople,
    responsive: "standard",
    rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS_PEOPLE,
    filterConfigs: FILTER_CONFIGS_PEOPLE,
    page: page,
    serverSide: true,
    isPeople: true,
    totalCount: count,
    onServerSidePagination: handleServerSidePagination,
    onServerSideSearch: handleServerSideSearch,
    onServerSideFilter: handleServerSideFilter,
    onServerSideSort: handleServerSideSort,
  };

  /**
 * Gets called when clicked on Add new button.
 */
  const handleAddNew = (actionId) => {
    switch (actionId) {
      case 0:
        history.push(`/people/timekeeper`, { fromTimeKeeper: true, identifier, selectedTab });
        break;
      case 2:
        history.push(`/people/admin`)
        break;
    }
  }

  const handleRowClick = (rowData, rowMeta) => {
    history.push(`/people/${externalUsersData[rowMeta?.dataIndex]?.id}/view`,{selectedPeopleTab :0})
  }

  /**
 * Add new action items for external users
 */
  const addNewActionItems = [
    {
      "actionId": 0,
      "label": "Timekeeper",
      "isAboveDivider": true
    },
    {
      "actionId": 2,
      "label": "Admin",
      "isAboveDivider": true
    }
  ]

  const associatedOrgFilter = formCsFilterObject("organizations.id", "match", identifier);
  const statusActiveFilter = formCsFilterObject("status", "match", "Active");

  const defaultQuery = {
    "filters": [
      CS_IS_ACTIVE_FILTER,
      CS_IS_EXTERNAL_USER_FILTER,
      associatedOrgFilter,
      statusActiveFilter
    ],
    "operation": "AND",
    "properties": null
  }

  /**
   * Function to call associate people list Service
   */
  const getPeopleData = (paginationQuery) => {
    setLoading(true);
    const sortQueryPeople = { ...sortQuery };
    if (searchQuery !== null && searchQuery !== undefined && Object.keys(searchQuery).length !== 0 && JSON.stringify(sortQuery) === JSON.stringify(CS_DEFAULT_SORT_QUERY)) {
      sortQueryPeople.sortBy = [CS_SCORE_SORT_OBJ, ...sortQuery?.sortBy];
    }
    const query = formCompositeSearchQuery([CS_SCOPES.PEOPLE], filterQuery, searchQuery, sortQueryPeople, paginationQuery, {}, defaultQuery);
    postService(
      COMPOSITE_SEARCH_SERVICE,
      `/composite-search/v1?returnCsv=false`,
      query
    )
      .then((res) => {
        setLoading(false)
        const peopleData = res?.data?.people;
        dispatch(setExternalUserData({
          nodeList: peopleData?.data,
          count: peopleData?.count
        }));
      })
      .catch((error) => {
        setLoading(false)
        addSnack({
          message: getErrorMessage(error)
        });
      });
  };

  useEffect(() => {
    const paginationQuery = {
      "offset": 0,
      "limit": rowsPerPagePeople,
    }
    getPeopleData(paginationQuery);
  }, [sortQuery, searchQuery, filterQuery]);

  /**
 * Cancel Export Modal
 */
  const onCancelBtnClk = () => {
    setExportLoading(false)
    setModalOpen(false)
  }

  /**
 * function will use when row is select/deselect
 */
  const handleRowSelectionChange = (currentSelect, allSelected, selectRowsData) => {
    const usersData = externalUsersData;
    const result = allSelected?.map(item => {
      return usersData && usersData[item?.index]
    });
    setSelectRowsData(selectRowsData);
    setRowsData(result);
  }

  /**
 * After click on create button download Actions
 */
  const onClickCreate = () => {
    setCreateExportLoading(true)
    let exportQuery = formCompositeSearchQuery([CS_SCOPES.PEOPLE], filterQuery, searchQuery, sortQuery, {}, { getAll: true }, {}, defaultQuery);
    if (exportSelection === 'currentSelection') {
      const result = rowsData?.map(a => a?.id);
      const selectionQuery = {
        "filters": [{
          "key": "id",
          "operation": "in",
          "value": result
        }],
        "operation": "AND",
        "properties": null
      }
      exportQuery = formCompositeSearchQuery([CS_SCOPES.PEOPLE], {}, {}, {}, {}, { getAll: true }, {}, defaultQuery, selectionQuery);
    }
    else if (exportSelection === 'currentPage') {
      const result = externalUsersData?.map(a => a?.id);
      const selectionQuery = {
        "filters": [{
          "key": "id",
          "operation": "in",
          "value": result
        }],
        "operation": "AND",
        "properties": null
      }
      exportQuery = formCompositeSearchQuery([CS_SCOPES.PEOPLE], {}, {}, {}, {}, { getAll: true }, {}, defaultQuery, selectionQuery);
    }

    postService(COMPOSITE_SEARCH_SERVICE, `/composite-search/v1?returnCsv=true`, exportQuery)
      .then((response) => {
        const data = response?.data;
        const contentType = response?.headers["content-type"];
        getExportedData(data, contentType, PEOPLE_DATA);
        setExportLoading(false);
        setCreateExportLoading(false);
        setModalOpen(false)
      })
      .catch((error) => {
        setExportLoading(false);
        setCreateExportLoading(false);
        setModalOpen(false)
        addSnack({
          message: getErrorMessage(error)
        });
      });
  }
  /**
   * After click on export button
   */
  const handleExportBtnClk = () => {
    setExportSelection(defaultExportOption)
    setModalOpen(true);
    setExportLoading(true);
    setCurrentPageExport(true);
  }

  /**
 * Gets called when clicked on action item
 */
  const onClickOption = (selectedId, tableMeta) => {
    if (selectedId === 0) {
      setDeactivateExternalUserModalOpen(true);
      setSelectedRowData(tableMeta);
    }
  }

  /**
 * Handle Deactivate external user Modal Actions
 */
  const handleDeactivate = (selectedRowData) => {
    setLoading(true);
    setDeactivateLoading(true);
    patchService(
      EXTERNAL_USER_SERVICE,
      `/people/v1/deactivate/${externalUsersData[selectedRowData?.rowIndex]?.id}`
    )
      .then((response) => {
        setDeactivateExternalUserModalOpen(false);
        setDeactivateLoading(false);
        setLoading(false);
        getPeopleData()

      })
      .catch((error) => {
        setLoading(false);
        addSnack({
          message: getErrorMessage(error)
        });
        setDeactivateExternalUserModalOpen(false);
        setDeactivateLoading(false);
      });
  }

  /**
  * deactivate ExternalUser modal
  */
  const deactivateExternalUserModal = () => {
    return (
      <Modal
        onClose={() => { setDeactivateExternalUserModalOpen(false) }}
        isOpen={deactivateExternalUserModalOpen}
        actions={<MakeDeactivateExternalUserModalActions setDeactivateExternalUserModalOpen={setDeactivateExternalUserModalOpen} deactivateLoading={deactivateLoading} handleDeactivate={handleDeactivate} selectedRowData={selectedRowData} />}
        size="medium"
        title={DEACTIVATE_USER}
        selectedRowData={selectedRowData}
      >
        <Grid>
          <Body as="p" size="large">
            <p>{USERS_DEACTIVATE_MSG}</p>
          </Body>
        </Grid>
      </Modal>
    );
  };

  const resetSelectedRows = () => {
    setSelectRowsData([]);
    setRowsData([]);
  }

  return (
    <div className={classes.rowStyle} data-testid="organization-people">
      <MuiDataTable
        columns={peopleColumns}
        data={externalUsersData}
        setTableColumns={setPeopleColumns}
        customOptions={peopleCustomOptions}
        showCustomAddNewButton={true}
        customAddNewButton={<AddNewActionPopover addNewActionItems={addNewActionItems} handleAddNew={handleAddNew} />}
        onAddNew={handleAddNew}
        onRowClick={handleRowClick}
        exportLoading={exportLoading}
        onExportClick={handleExportBtnClk}
        loading={loading}
        selectableRowsType={"multiple"}
        onRowSelection={handleRowSelectionChange}
        selectedRows={selectRowsData}
        allowedActions={getAllowedActionsList(accessibility)}
        resetSelectedRows={() => resetSelectedRows()}
      />
      {deactivateExternalUserModal()}
      <ExportModal
        title={PEOPLE}
        mode={modalOpen}
        onCancel={onCancelBtnClk}
        onCreate={onClickCreate}
        rowsData={rowsData}
        exportSelection={exportSelection}
        setExportSelection={setExportSelection}
        currentPageExport={currentPageExport}
        showExportAllOption={showExportAllOption}
        setCurrentPageExport={setCurrentPageExport}
        exportLoading={createExportLoading}
      />
    </div>
  );
};

export default ActivePeople;
