export const PAGE_NOT_FOUND_MSG = "We couldn’t find this page.";
export const ERROR_MSG = "Try searching or go back to the previous page.";
export const SOMETHING_WENT_WRONG =  "Something went wrong!";
export const PASSWORD_RESET_SUCCESS =  "Password reset successfully.";
export const LOGIN_AGAIN =  "Please Login again.";
export const CLAIM_EVIDENCE_ADDED_MSG = "Claims Evidence added successfully."
export const SAVE_MESSAGE = "Changes were saved successfully.";
export const DELETE_MESSAGE = "Delete was successful.";
export const PARTY_CREATE_MESSAGE = "Party was created successfully.";
export const PARTY_UPDATED_MESSAGE = "Party was updated successfully.";
export const PARTY_DELETED_MESSAGE = "Party was deleted successfully.";
export const EXISTING_BUDGET_ALERT_MESSAGE="You may continue with your selections or make edits."
export const EXISTING_BUDGET_ALERT_MESSAGE_1="Budget already exists in the system for this matter/organization and is currently in status"
export const EXISTING_BUDGET_ALERT_MESSAGE_2="This budget would be recorded as a new version of the existing budget."
export const MATTERS_LINK_SUCCESS_MESSAGE = "Matter(s) linked successfully.";
export const APPLICATION_ERROR_MSG =  "Application failed to load, please try again later.";
export const NOTE_CREATE_WARNING_MSG = "Note description will not be editable after creation.";
export const NOTE_SAVE_MESSAGE = "Note created successfully.";
export const NOTE_LINK_COPY_MESSAGE = "Note link copied to clipboard.";
export const LOGOUT_SUCCESS_MSG = "You have been logged out.";
export const PEOPLE_UNLINK_SUCCESS_MESSAGE = "Timekeeper has been unlinked successfully.";
export const PEOPLE_LINK_SUCCESS_MESSAGE = "Request has been submitted successfully.";
export const LNG_EXISTING_MATTER_ACCESS_REQUEST="Matter Assign Request already exists for the selected combination."
export const UNAUTHORISED_ACCESS_MSG = "You don't have permission to access this application. Please contact system administrator.";
export const REQUEST_ACCESS_MSG = " Please contact an admin to get access.";
export const ROLE_CODE_EMPTY_MESSAGE = "Role is not assigned to logged In user. Please contact system administrator.";
export const LOADING = "Loading";
export const NO_OPTIONS = "No options";
export const WARNING_BUTTON_TITLE="View Messages"
export const INVOICE_WARNING_MESSAGE = "You are viewing invoice-level messages for Invoice.";
export const INVOICE_MESSAGES="Invoice Messages";
export const INVOICE_UPDATE_MESSAGE = 'Invoice was updated successfully.';
export const INVOICE_FAILURE_STATUS_MSG="We’ve encountered an error while processing the invoice. Please resubmit the invoice using the resubmit button."
export const INVOICE_WARNING_TITLE_MESSAGE = "Invoice-level message(s) are in need of attention.";
export const INVOICE_SAVE_MESSAGE = 'Invoice was created successfully.';
export const BUDGET_REJECT_MESSAGE = "Budget Rejected Successfully.";
export const RESTORE_SUCCCESS_MESSAGE = "Restored successfully.";
export const BUDGET_UPDATE_MESSAGE = 'Budget was updated successfully.';
export const BUDGET_VIEW_VERSION_ERROR_MESSAGE = 'Unable to view the budget version at present.';
export const BUDGET_SAVE_MESSAGE = 'Budget was created successfully.';
export const MATTER_DEFINITIONS_ERROR_MESSAGE = 'Uh oh! Something went wrong, please retry again in some time.';
export const MATTER_DETAILS_UPDATE_SUCCESS = "Matter Details updated Successfully.";
export const MATTER_MESSAGE =" Matters Selected";
export const TIMEKEEPER_MESSAGE = " Timekeepers Selected";
export const USER_SAVE_MESSAGE = 'User was created successfully.';
export const MSR_CREATE_SUCCESS_MSG = "Matter Specific Rates Created successfully.";
export const CREATE_PRATICE_AREA_SUCCESS_MSG = "Practice Area Rate Created Successfully.";
export const PREVIEW_NOT_SUPPORTED_MSG = "Preview is not available for this file! Try downloading instead.";
export const ORGANIZATION_UPDATE_MSG = "Organization details updated successfully.";
export const USER_DETAILS_UPDATE_MSG = "User details updated successfully.";
export const TAGS_EDITED_SUCCESS_MSG = "Tags edited successfully.";
export const FILE_RENAME_MSG = "File renamed successfully.";
export const FILE_UPLOAD_MSG = "File uploaded successfully.";
export const NO_MATTER_MSG = "No Matters Were Selected.";
export const NO_TIMEKEEPERS_MSG = "No Timekeepers Were Selected.";
export const TIMEKEEPER_REMOVE_MSG = "Timekeepers to Remove:";
export const INVOICE_APPROVE_MSG = `Do you wish to proceed with approving this invoice?`;
export const INVOICE_REJECT_MSG = `Please enter a rejection reason to proceed.`;
export const INVOICE_HOLD_MSG = `Do you wish to place a hold on this invoice?`;
export const INVOICE_APPEALS_MSG = `Do you wish to send this invoice back to Appeals?`;
export const INVOICE_LINEITEM_UPDATE_SUCCESS = `Line Item updated successfully.`;
export const INVOICE_STATUS_CHANGE_SUCCESS = "Invoice Status changed successfully.";
export const NO_COMMENT_AVAILABLE = `No Comment Available.`;
export const NO_REASON_AVAILABLE = `No Reason Available.`;
export const USERS_DEACTIVATE_MSG ="Warning: Deactivating a user will revoke their eWMMS access and, if the user is a timekeeper, will prevent submission of unbilled time.";
export const USER_INACTIVITY_MSG = "You have been logged out due to Inactivity. Please login again."
export const INVALID_DATE_FORMAT_MESSAGE = "Please enter a valid date format ( MM/DD/YYYY )."
export const INVALID_DATE_MESSAGE = "Please enter a valid date."
export const INVALID_DATE_RANGE_MESSAGE = "Please select date in a valid range."
export const FILE_UPLOAD_SIZE_LIMIT_MSG = "Total file size exceeded 10MB.";
export const MAX_FILE_UPLOAD_SIZE_MSG = "Maximum file upload size is 10 MB."
export const INVOICE_UPLOAD_CONFIRMATION_MSG = "Are you sure you want to close this window?";
export const INVOICE_SUBMISSION_IN_PROGRESS_MSG = "Invoice Submission in progress";
export const INVALID_LEDES_FORMAT_MSG = "Please enter a valid LEDES 98B formatted line items"
export const INVALID_LEDES_UPLOAD_DATA_FORMAT_MSG = "Please use the compatible invoice file data format."
export const LINK_COPY_MESSAGE = "Link copied to clipboard!";
export const ERROR_MSG_WHILE_FETCHING_DOCUMENT = "Something went wrong while fetching document. Please try again later";
export const LEDES_FILE_PROCESSED_SUCCESS_MSG = "The File has been processed successfully. Please scroll below the see the Line Items.";
export const OLD_PASSWORD_ENTERED_MSG = "New password matches with one of the old passwords. Please try with new password again";
export const OTP_EXPIRED_MSG = "OTP is Invalid/Expired or has been used already.\nPlease generate new OTP";
export const EMAIL_PASSWORD_INCORRECT = "user id or password is incorrect";
export const GET_FACILITY_FAILED_MESSAGE = "Something went wrong while fetching facility details. Please try again later.";
export const UNACCESSIBLE_MATTER_MSG = "You don't have access to view this matter"