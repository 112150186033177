import { useSelector } from 'react-redux';
import { getISODate } from './dateUtils';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import { matterStatusValues, adminExternalUsersStatusValues, sopStatusConversion, matterSections, jobStatusValues,
  CS_DEFAULT_PAGINATION_QUERY, CS_SCOPES,
  ADMIN_LABEL,
  ADMIN_TIMEKEEPER_LABEL,
  TIMEKEEPER_LABEL} from '../constants/constants';
import { BACKEND_DATE_FORMAT, DATE_YYYY_MM_DD, MOMENT_DATE_FORMAT, TIME_HH_MM_SS } from '../constants/dateFormats';
import { matterStatusTagColors, adminExternalUsersTagColors,WHITE, jobStatusTagColors } from '../constants/colors';
import { createUseStyles } from "react-jss";
import { SPACING } from "../constants/styleGuide";
import { Operations } from '../axios/operations';
import { getService, postService } from '../axios/axios';
import { MATTER_DETAILS_DEFINITION, COMPOSITE_SEARCH_SERVICE, PEOPLE_SEARCH_SERVICE } from '../constants/baseURLs';
import { ERROR_CODES } from '../constants/errorCodes';
import { SOMETHING_WENT_WRONG } from '../constants/messages';

import { formCompositeSearchQuery, formCsFilterObject } from "./csQueryUtils";
import { matterDataMappingforDetail } from "./tableUtils";
import { convertToUTCDateTime } from './utcUtils';
import { Caption } from '@walmart-web/livingdesign-components';
import { isValueEmptyCheck, isValueValid } from './validationUtils';
import { formatPhoneNumber } from './commonUtils';

const useStyles = createUseStyles({
  contentSpacingLeft16: {
    paddingLeft: SPACING.s16,
    paddingTop: SPACING.s4
  },
  buttonGroup: {
    alignSelf: "flex-end",
    marginLeft: "auto",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    backgroundColor: WHITE,
    padding: 16,
  },
  accordionDetails: {
    width: "100%",
    justifyContent: "space-between",
    marginLeft: 8
  },
  accordion: {
    margin: "24px 0",
  },
  noResults: {
    width: "300px",
    margin: "80px auto",
    textAlign: "center",
    padding: "32px 24px 40px",
    "& h4": {
      fontWeight: "bold",
      fontSize: "16px",
    },
    "& p": {
      fontSize: "14px",
    },
  },
});

export const isEmptyObj = (obj) => Object.keys(obj).length === 0;

/**
 *
 * @param {string} name
 * @param {string} operator
 * @param {string} value
 * @param {string} type - array/name
 * @returns
 */
export const formSearchQuery = (name, operator, value, type) => {
  let query = '';
  switch (type) {
    case 'array':
      const inValue = value?.map((e) => `'${e}'`).join(',');
      query = `${name}:${operator}:%5B${inValue}%5D`;
      break;

    case 'name':
      const [firstName, lastName] = value?.split(' ');
      query = `${name}.firstName:${operator}:'${firstName}'`;
      if (lastName) {
        query += ` and ${name}.lastName:${operator}:'${lastName}'`;
      }
      break;
    case 'defaultDate':
      const isDate = moment(value).format(DATE_YYYY_MM_DD);
      query = moment(isDate).isValid() ? `${name}:GTE:dt'${isDate}T00:00:00.000Z'`: '';
      break;
    case 'date':
      const isoDate = moment(value).format(DATE_YYYY_MM_DD);
      const nextDate = moment(value).add(1, 'days').format(DATE_YYYY_MM_DD)
      query = moment(isoDate).isValid() ? `(${name}:GTE:dt'${isoDate}T00:00:00.000Z' and ${name}:LTE:dt'${nextDate}T00:00:00.000Z')` : '';
      break;
    case 'number':
      query = `${name}:${operator}:'${value}'`;
      query = !isNaN(value) ? query : '';
      break;
    case 'fixedDate':
      const nextDay = moment(value[0]).add(1, 'days');
      query = `${name}:GTE:${getISODate(value)} and ${name}:LTE:${getISODate(nextDay)}`
      break;
    case 'notesContent':
      query = `(title:${operator}:'%${value}%' OR ${name}:${operator}:'%${value}%')`;
      break;
    case 'title':
      query = `${name}:${operator}:'%${value}%'`;
      break;
    case 'tag':
      query = `${name}:${operator}:'%${value}%'`;
      break;

    default:
      query = `${name}:${operator}:'${value}'`;
  }
  return query;
}

/**
 * Gets called when user download the export file
 * server side
 * @public
 */
 export const getExportedData = (payload, contentType, downloadName) => {
  const url = window.URL.createObjectURL(
    new Blob([payload], { type: contentType })
  );
  let ext = ".csv";
  if (contentType === "application/octet-stream") {
    ext = ".zip";
  }
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${downloadName}${ext}`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

/**
 * Gets called when user download the export file
 * client side
 * @public
 */
 export const exportClientData = (budgets, exportSelection, rowsData, downloadName) => {
  let csvData = jsonToCsv(budgets);
  if(exportSelection === 'currentSelection'){
    csvData = jsonToCsv(rowsData);
  }else if(exportSelection === 'currentPage'){
    csvData = jsonToCsv(budgets);
  }
  //Download the file as CSV
  const url = window.URL.createObjectURL(
    new Blob([csvData]),
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${downloadName}.csv`);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}


export const isObjectDirty = (obj1, obj2, key) => {
  if (obj1 && obj2) {
    return obj1[key] !== obj2[key]
  } else if (obj1 || obj2) {
    return true;
  } else {
    return false;
  }
}

export const isDateDirty = (d1, d2) => {
  const date1 = new Date(d1).getDate();
  const date2 = new Date(d2).getDate();

  return date1 > date2 || date1 < date2;
}

export const titleCase = (string) => {
  return string ? string[0].toUpperCase() + string.slice(1).toLowerCase() : "";
}

export const isTimeDirty = (t1, t2) => {
  return t1 !== t2;
}

export const compare = (a, b) => {
  if (a.last_nom < b.last_nom) {
    return -1;
  }
  if (a.last_nom > b.last_nom) {
    return 1;
  }
  return 0;
}

export const getColorVariant = (value) => {
  let color = "";
  let variant = "";

  if (matterStatusValues.indexOf(value) > -1) {
    color = matterStatusTagColors[value]?.color;
    variant = matterStatusTagColors[value]?.variant;
  } else if (adminExternalUsersStatusValues.indexOf(value) > -1) {
    color = adminExternalUsersTagColors[value]?.color;
    variant = adminExternalUsersTagColors[value]?.variant;
  } else if (jobStatusValues.indexOf(value) > -1) {
    color = jobStatusTagColors[value]?.color;
    variant = jobStatusTagColors[value]?.variant;
  }else {
    color = "gray";
    variant = "tertiary";
  }
  return { color, variant };
};

/*
* convert json data to csv
*/
export const jsonToCsv = (data) => {
  const items = data;
  // specify how you want to handle null values here
  const replacer = (key, value) => value === null ? '' : value;
  const header = Object.keys(items[0]);
  let csv = items?.map(row => header?.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
  csv.unshift(header.join(','));
  csv = csv.join('\r\n');
  return csv;
}

export const converCamelCaseToCapital = (text) => {
  const result = text.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}

/**
 * Convert every first letter capital in a string
*/
export const convertFirstLetterCapital = (str) => {
  let splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(' ');
}

/**
 * Convert every first letter capital in a string
*/
export const statusConversion = (value) => {
  let result;
  switch (value) {
    case sopStatusConversion.ACCEPTED_RELATED:
      result = 'accepted-related';
      break;
    case sopStatusConversion.ACCEPTED_NEW:
      result = 'accepted-new';
      break;
    case sopStatusConversion.ARCHIVED:
      result = 'archived';
      break;
    case sopStatusConversion.FORWARDED:
      result = 'forwarded';
      break;
    case sopStatusConversion.IN_REVIEW:
      result = 'in-review';
      break;
    case sopStatusConversion.NEW:
      result = 'new';
      break;
    case sopStatusConversion.NEW_CORRECTED:
      result = 'new-corrected';
      break;
  }
  return result;
}

/**
 * Convert every first letter capital in a string
*/
export const matterSectionConversion = (value) => {
  switch (value) {
    case matterSections.BASIC:
      return 'Basic Matter Fields';
    case matterSections.SOP:
      return 'Service of Process';
    case matterSections.IDENTIFICATION:
      return 'Matter Identification Fields';
    case matterSections.COURT:
      return 'Court Info';
    case matterSections.CLOSURE:
      return 'Closure Fields';
    case matterSections.LOCATION:
      return 'Location Fields & Store Info';
    case matterSections.MISC:
      return 'Misc Lawsuit Info';
    case matterSections.PRODUCT:
      return 'Products Information';
    case matterSections.NEW:
      return 'New Fields Requested';
    case matterSections.PHARMACY:
      return 'Pharmacy Matters';
    case matterSections.TRUCKING:
      return 'Trucking Matters';
    default:
      return value;
  }
}

//conversion of Color
export const converColorValue = (value) => {
  return value?.charAt(0).toUpperCase() + value?.slice(1).toLowerCase();
}

// Date and time Converison Fuxntions
const DATE_FORMAT_TZ = "YYYY-MM-DDTHH:mm:ss.SSS";
export const fromDateConversion = (date) => {
  const startDatedate = new Date(date);
  const startDateTimeZone = moment(startDatedate).startOf('day').format(DATE_FORMAT_TZ);
  return moment.utc(moment(startDateTimeZone)).format(BACKEND_DATE_FORMAT);
}

export const startTimeConversion = (date) => {
const startDatedate = new Date(date);
const startDateTimeZone = moment(startDatedate).startOf('day').format(DATE_FORMAT_TZ);
return moment.utc(moment(startDateTimeZone)).format(TIME_HH_MM_SS);
}

export const toDateConversion = (date) => {
  const endDatedate = new Date(date);
  const endDateTimeZone = moment(endDatedate).endOf('day').format(DATE_FORMAT_TZ);
  return moment.utc(moment(endDateTimeZone)).format(BACKEND_DATE_FORMAT);
}

export const endTimeConversion = (date) => {
const endDatedate = new Date(date);
const endDateTimeZone = moment(endDatedate).endOf('day').format(DATE_FORMAT_TZ);
return moment.utc(moment(endDateTimeZone)).format(TIME_HH_MM_SS);
}

export const matterFilterConfig = (filterValues) => {
  const matterQuery = {
    metadata: []
  };
  const andArray = [];
  const orArray = [];
  Object.keys(filterValues).forEach(function (key) {
    if (filterValues?.[key].length !== 0) {
      if (key === "status") {
        const filterArray = [];
        filterValues?.[key]?.forEach(function (item, index) {
          const filter = {
            "property": `${key}`,
            "value": `${item}`,
            "operator": "="
          };
          filterArray.push(filter);

        });
        const operator = "OR";
        const orObj = {
          "filters": filterArray,
          "op": operator,
          "metadata": null
        };
        orArray.push(orObj);
      }
      else {
        filterValues?.[key]?.forEach(function (item, index) {
          const filter = {
            "property": `${key}`,
            "value": `${item}`,
            "operator": "="
          };
          andArray.push(filter);

        });
      }
    }
  });
  const query = {
    "filters": andArray,
    "op": "AND",
    "metadata": orArray
  }
  matterQuery.metadata.push(query);
  return { matterQuery };
}

/**
 * Gets called when searching for matters
 * @param {object} filterValues
*/
export const formSearchMatterQuery = (filterValues) => {
  const matterQuery = {
    metadata: []
  };
  const filterArray = [];
  Object.keys(filterValues).forEach(function (key) {
    filterValues?.[key]?.forEach(function (item, index) {
      const filter = {
        "property": `${key}`,
        "value": `${item}`,
        "operator": "like"
      };
      filterArray.push(filter);
    });
  });
  const orObj = {
    "filters": filterArray,
    "op": "OR",
    "metadata": null
  };
  matterQuery.metadata.push(orObj);
  return { matterQuery };
}

export const matterDataMapping = (payload) => {
  const data = {
		matters: [],
		count: payload?.count
	};

	payload?.nodeList?.map(result => {
		data.matters.push({
            id: result?.id,
            matterNumber: result?.matterNumber,
            matterName: result?.matterName,
            status: result?.status,
            matterType: result?.matterType,
            matterSubType: result?.matterSubType,
            matterCreateDate: moment(result?.creationDate).format("MM/DD/YYYY"),
            closureDate: result?.closureDate ? moment(result.closureDate).format(MOMENT_DATE_FORMAT) : '-',
            practiceArea: result?.practiceArea,
			      practiceAreaCode: result?.practiceAreaCode,
            createdBy: result?.createdBy,
		})
	});
  return data;
}

/**
 *
 * @param {object} filterQuery
 * @param {object} searchQuery
 * @returns combined query
 */
export const joinMatterQueries = (filterQuery, searchQuery) => {
  const query = JSON.parse(JSON.stringify(filterQuery));
  query.metadata.push(searchQuery.metadata[0]);
  return query.metadata;
}

export const documentFilterConfig = (filterValues) => {
  const documentQuery = [];
  let rawSearch = "";
  filterValues && Object?.keys(filterValues)?.forEach(function (key) {
    if (key === 'name') {
      if (filterValues?.[key]?.[0]) {
        rawSearch = filterValues?.[key]?.[0];
      }
    } else
    if (key === 'createdBy' || key === 'tags' || key === 'fileExtension' || key === 'userId' || key?.includes('matterNumber') || key === 'matterName' || key === 'practiceArea' || key === 'version') {
          let newKey = key;
          if (key === 'tags' || key?.includes('matterNumber') || key === 'matterName' || key === 'practiceArea') {
            newKey = `metaData.${key}`;
          } else if (key === 'userId') {
            newKey = 'createdBy'
          }
          // In case of multiple selections we need to pass it inside "query"
          if (filterValues?.[key]?.length > 1) {
            const queryStructure = {
              query: {
                condition: Operations.OR,
                queryParameters: []
              }
            }
            filterValues?.[key]?.forEach((name) => {
              queryStructure?.query?.queryParameters?.push({
                key: newKey,
                operation: Operations.MATCH,
                value: name
              })
            })
            documentQuery.push(queryStructure);
          } else if (filterValues?.[key]?.[0]) {
            documentQuery.push(
              {
                key: newKey,
                operation: Operations.MATCH,
                value: key === 'version' ? filterValues?.[key]?.[0]?.toLowerCase() : filterValues?.[key]?.[0]
              }
            )
          }
        }
        // createdTs is date range
        else if (key === "createdTimeStamp") {
          // at index 0 - start date will be stored
          if (filterValues?.[key]?.["start"] || filterValues?.[key]?.[0]) {
            documentQuery.push(
              {
                key: "createdTimeStamp",
                operation: "gte",
                value: convertToUTCDateTime({ date: filterValues?.[key]?.["start"] ?? filterValues?.[key]?.[0], time: "00:00:00" })
              }
            )
          }
          // at index 1 - end date will be stored
          if (filterValues?.[key]?.["end"] || filterValues?.[key]?.[1]) {
            documentQuery.push({
              key: "createdTimeStamp",
              operation: "lte",
              value: convertToUTCDateTime({ date: filterValues?.[key]?.["end"] ?? filterValues?.[key]?.[1], time: "23:59:59" })
            })
          }
        }
  });
  return { documentQuery, rawSearch };
}

export const getCurrentTimeZone = () => {
  const date = new Date()
  const gmtHours = -date.getTimezoneOffset() / 60;
  return moment(gmtHours).format("Z");
}

/**
 * Function to make the API call to get Matter Definitions for all Practice Areas, and then format the response as per our needs
 */
 export const getMatterDefinitions = () => {
  return new Promise((resolve, reject)=>{
    getService(
      MATTER_DETAILS_DEFINITION,
      `/definitions/v1/LNG`
    )
      .then((response) => {
        let matterDefinitions = {}
        // Convert list of definitions to a map using objectType as key, for faster indexing later
        if(response?.data?.definitionDTOList){
          matterDefinitions = response?.data?.definitionDTOList?.reduce(
            function(definitionMap, definitionObject) {
              // In case the definition object is missing an objectType, use a reserved key indicating it is an illegal object in the response
              definitionMap[definitionObject.objectType ?? "ILLEGAL_DEFINITION_NODE"] = definitionObject;
              return definitionMap;
            }, {}
          );
        }
        resolve(matterDefinitions);
      })
      .catch((error) => {
        reject(error);
      })
  })
}

/**
 * Function to make API call to read Matter Information by using Matter ID.
 */
export const getMatterData = (matterId,orgIdentifier, userRelation ={}, sensitiveMatterQuery={}) => {
  const defaultQuery = {
    "filters": [formCsFilterObject("isActive", "match", true),
    formCsFilterObject("id", "match", matterId)],
    "operation": "AND",
    "properties": null
  }
  const query = formCompositeSearchQuery([CS_SCOPES.MATTER], {}, {}, {}, CS_DEFAULT_PAGINATION_QUERY, {}, defaultQuery, userRelation, sensitiveMatterQuery);

  return new Promise((resolve, reject) => {
    postService(
      COMPOSITE_SEARCH_SERVICE,
      `/composite-search/v1?returnCsv=false&organizationId=${orgIdentifier}&options=restrictMode`,
      query
    )
      .then((response) => {
        const matterResult = matterDataMappingforDetail(response?.data?.matter);
        const matterResponse = {
          data: {
            responseCode: "OK",
            result: {
              matter: matterResult?.matters[0],
              count: response?.data?.matter?.count
            }
          },
          status: 200
        }
        resolve(matterResponse);
      })
      .catch((error) => {
        reject(error);
      })
  })
}

/**
 * Utility function to perform custom sort on 'matter number' field
 * @param {string} order
 */
 export const handleMatterNumberSort = (order) => {
  return (obj1, obj2) => {
    const year1 = parseInt(obj1.data.split("-")[0]);
    const year2 = parseInt(obj2.data.split("-")[0]);
    const number1 = parseInt(obj1.data.split("-")[1]);
    const number2 = parseInt(obj2.data.split("-")[1]);
    if(year1 > year2){
      return (order === 'asc' ? 1 : -1)
    }
    else if (year1 < year2){
      return (order === 'asc' ? -1 : 1)
    }
    else{
      return (number1 - number2) * (order === 'asc' ? 1 : -1)
    }
  };
}

// Render Ternary operation
export const renderTernary = (condition, truthyValue, falsyValue) => {
  if(condition) {
    return truthyValue
  }
  return falsyValue;
}

/**
 * This funtion will be used to convert Practice Area Name to Code
 */
export const convertPANameToCode = (practiceAreaName = "", practiceAreaNameToCodeObj = {}) => {
  if(practiceAreaName !== "" || practiceAreaNameToCodeObj.hasOwnProperty(practiceAreaName)) {
    return practiceAreaNameToCodeObj[practiceAreaName]
  }
  return ""
}

/**
 * This funtion will be used to convert Practice Area Code to Name
 */
export const convertPACodeToName = (practiceAreaCode = "", practiceAreaCodeToNameObj = {}) => {
  if(practiceAreaCode !== "" || practiceAreaCodeToNameObj.hasOwnProperty(practiceAreaCode)) {
    return practiceAreaCodeToNameObj[practiceAreaCode]
  }
  return ""
}

/**
 * To extract the tab list and tabIndexMap
 * @param {*} tablist
 * @returns
 */
export const getTabList = (tablist) => {
  const listOfTabs = [];
  const tabIndexMap = {};
  for (const key in tablist) {
    const item = tablist[key];
    if (item?.showTab) {
      listOfTabs.push(item?.label)
      if(item?.tabIndex !== undefined) {
        tabIndexMap[`${item?.label}`] = item?.tabIndex;
      }
    }
  }
  return { listOfTabs, tabIndexMap };
}

/* Function to generate secret key */
export const generateSecretKey = () => {
  const key = CryptoJS.lib.WordArray.random(32);
  return key.toString(CryptoJS.enc.Base64);
}
/* Function to encrypt the string */
export const handleEncryptedString = (stringToEncrypt, secretKey) => {
  const key = CryptoJS.enc.Base64.parse(secretKey);
  const iv = CryptoJS.lib.WordArray.random(16);
  const encrypted = CryptoJS.AES.encrypt(
    stringToEncrypt,
    key,
    {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }
  );
  const base64IV = iv.toString(CryptoJS.enc.Base64);
  const base64Encrypted = encrypted.toString();
  return `${base64IV}:${base64Encrypted}`;
}

export const encryptUserDetail = (stringToEncrypt, secretKey) =>{
  return CryptoJS.AES.encrypt(stringToEncrypt, secretKey).toString();
}

export const decryptUserDetail = (stringToDecrypt, secretKey) =>{
  return CryptoJS.AES.decrypt(stringToDecrypt, secretKey).toString(CryptoJS.enc.Utf8);
}


export const useUserAssociatedRelation = (accessibility) => {
  const userDetail = useSelector((state)=>state?.user?.userDetail?.attributes);
  let relations= [];
    if(!accessibility?.viewAll) {
      relations = [
        {
          "key": "people.id",
          "value": userDetail?.userUniqueId,
          "operation": "match"
        }
      ]
    }
    return relations;
}

/**
 * This function will return error message from error code
 * @param {*} error
 * @returns - error message mapped to error code
 * @returns - default error message if mapping is not present
 */
 export const getErrorMessage = (error) => {
  let errorCode = error?.response?.data?.errorCode || error?.response?.data?.params?.err;
  //convert error to a json obj
  try
  {
    errorCode = JSON.parse(error?.response?.data)?.errorCode
  }
  catch (err){
    //If error is a json obj we send the errorCode as it is
  }
  const backendErrMsg = error?.response?.data?.message;
  const errorMsg = ERROR_CODES?.[errorCode];
  // condition to show backend message as is
  if(errorMsg && errorMsg === errorCode) {
    return backendErrMsg;
  }
  if(errorMsg){
    return errorMsg;
  } else {
    return SOMETHING_WENT_WRONG
  }
 }

 /**
 * This function will return true or false based on the string provided
 * @param {*} str
 * @returns - returns true or false
 */
 export function isNumeric(str) {
  if (typeof str != "string") {
    return false
  }
  return !isNaN(str) && !isNaN(parseFloat(str))
}


/**
 * Method to highlight a search term
 * @param {*} optionLabel
 * @returns Search Text highlighted
 */
export const getHighlightedText = (optionLabel, searchText) => {
  // Split on highlight term and include term into parts, ignore case
  if (searchText && searchText?.trim() !== "" && optionLabel?.toLowerCase()?.includes(searchText?.toLowerCase())) {
    const parts = optionLabel?.split(new RegExp(`(${searchText})`, 'gi'));
    return (
      <>
        {parts?.map((part, i) =>
          <span key={part} style={part.toLowerCase() === searchText?.toLowerCase() ? { fontWeight: 'bold', fontFamily: "Bogle" } : { fontFamily: "Bogle" }}>
            {`${part}`}
          </span>)
        }
      </>);
  } else {
    return optionLabel
  }
}

/**
 * Gets called when a string value needs to be cleaned.
 */
export const cleanValueString = (val) => {
  if(val === undefined || val === null || val === '')
  {
    return '-';
  }
  else
  {
    return val;
  }
}

/**
 * This funtion will be used to get rejection reason from rejection code
 */
export const getRejectionReason = (code, rejectionReasons) => {
	const itemIndex =rejectionReasons?.masterData?.findIndex((a => a.code.toString() === code));
  return renderTernary(rejectionReasons?.masterData[itemIndex]?.description,rejectionReasons?.masterData[itemIndex]?.description,"-");
}

/**
   * Function to convert file size to proper range
   * @param {*} x - file size
   * @returns file size in pretty unit range
   */
export const niceBytes = (x) =>{
  const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  let l = 0, n = parseInt(x, 10) || 0;

  while(n >= 1024 && ++l){
      n = n/1024;
  }

  return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}

// To avoid typing e, E and + in input as number fields allow these charachters
export const onInputNumberKeyDown = (e) => {
  if (['e', 'E', '+'].includes(e.key)) {
    e.preventDefault();
  }
}

// To avoid typing e, E, + and - in input as number fields allow these charachters
export const onInputNumberChange = (e) => {
  if (['e', 'E', '+', '-'].includes(e.key)) {
    e.preventDefault();
  }
}

/* Function to return Practice Area Name Status Code Cell */
export const returnPracticeAreaName = (value, practiceAreas) => {
  const practiceAreaObj = practiceAreas?.find(o => o?.code?.toLowerCase() === value?.toLowerCase());
  return practiceAreaObj?.name;
}

export const getSensitiveMatterPayload = (viewAllMatter = true, userId) => {
  let filterQuery = {};
    filterQuery = {
      "filters": [
        {
          "key": "isSensitive",
          "value": false,
          "operation": "match"
        }
      ],
      "operation": "OR",
      "properties": [
        {
          "filters": [
            {
              "key": "people.id",
              "operation": "match",
              "value": userId
            }
          ],
          "operation": "AND",
          "properties": null
        }
      ]
    }
  return filterQuery;
}

export const displayValue = (value, tableMeta) => {
  return (
    <Caption as="p">{isValueEmptyCheck(value)}</Caption>
  )
}

export const phonenoRender = (value, tableMeta) => {
  const val = value ? formatPhoneNumber(value) : '-';
  return <p id={`table-row-phoneno-${tableMeta?.rowIndex}`}>{val}</p>
}

/**
 * Utility function to sort array of objects using Date Timestamp
 * @param {array} arrayList
 * @param {string} key
 * @param {string} order
 */
export const sortByString = (arrayList, key, order = 'asc') => {
  if(arrayList.length === 0 || !Array.isArray(arrayList) || key === '' || key === undefined) {
    return [];
  }
  if(order === 'asc') {
    const arr = arrayList.sort((a, b) => a[key].localeCompare(b[key]))
    return arr;
  } else {
    const arr = arrayList.sort((a, b) => b[key].localeCompare(a[key]))
    return arr;
  }
}

export const getLeadAttorneyParalegalQuery = (id, property) => {
  return {
      "countQuery": false,
      "graphId": "LNG",
      "metadata": [{
          "filters": [{
              "operator": "=",
              "property": "isActive",
              "value": true
            }
          ]
        }
      ],
      "relations": [{
          "objectType": "MATTER",
          "filters": [{
              "filters": [
                {
                  "operator": "=",
                  "property": property,
                  "value": true
                }
              ],
              "op": "OR"
            }
          ],
          "metadata": [{
              "filters": [
                {
                  "operator": "=",
                  "property": "id",
                  "value": id
                },
                {
                  "operator": "=",
                  "property": "isActive",
                  "value": true
                }
              ],
              "op": "AND"
            }
          ]
        }
      ],
      "op": "AND",
      "nodeType": "DATA_NODE",
      "objectType": "PEOPLE"
    }
}

// Fetching lead attorney and paralegal from graph search for a Matter using Matter ID
export const getLeadAttorneyOrParalegalWithMatterID = (matterId, property) => {
  const body = getLeadAttorneyParalegalQuery(matterId, property);
  return new Promise((resolve, reject) => {
      postService(PEOPLE_SEARCH_SERVICE, `/search/people/v1/?isCsv=${false}`, body)
      .then((response) => {
          resolve({...response?.data?.result?.peopleList?.nodeList[0]})
      })
      .catch((error)=>{
          reject(error)
      })
  })
}

// Function to fetch Highest Version of Budget in Create Budget Screen
export const getHighestVersion = (data) => {
  // Extract the highest version from the data
  const highestVersion = data?.reduce((max, current) => {
    return current?.version > max?.version ? current : max;
  });

  return highestVersion?.version;
}

/**
 * mapTimeKeeperCSResponse
 * function to map CS response of Timekeeper
 */
export const mapTimeKeeperCSResponse = (payload,orgIdentifier) => {
  const data = {
		people: [],
		count: payload?.count
	};
	payload?.map(result => {
    if(result?.organizationId === orgIdentifier && result?.isExternalUser === true){
		data?.people?.push({
			identifier: result?.id,
      organization: result?.organizationName,
			...result
		})}
	});
	return data;
}

/**
 * This function will return role name from role code
 * @param {*} roleCode, @param {*} isTimeKeeperRoleEnabled
 * @returns - role name mapped to role code
 * @returns - default role name if mapping is not present
 */
export const getRequestedRoleDataForTimekeeper = (roleCode, isTimeKeeperRoleEnabled) => {
  let isRoleValid = isValueValid(roleCode);
  let userRole;
  if (roleCode === TIMEKEEPER_LABEL) {
    userRole = TIMEKEEPER_LABEL
  } else if (roleCode === ADMIN_LABEL) {
    if (isTimeKeeperRoleEnabled) {
      userRole = ADMIN_TIMEKEEPER_LABEL
    } else {
      userRole = ADMIN_LABEL
    }
  } else {
    isRoleValid = false
  }
  const roles = renderTernary(isRoleValid, userRole, '-');
  return roles;
}
