import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import MuiDataTable from "../../../components/Table/MuiDataTable/MuiDataTable";
import ProgressBar from "../../../components/ProgressBar/ProgressBar";
import PropTypes from "prop-types";

import {
	useSnackbar,
} from "@walmart-web/livingdesign-components";
import {
	LIGHT_RED,
	LIGHT_GREEN,
	LIGHT_YELLOW
} from "../../../constants/colors";
import {
	CS_SCOPES,
	MATTER_NUMBER,
	CS_DEFAULT_SORT_QUERY,
	CS_SCORE_SORT_OBJ,
	EXPORT_MAX_LIMIT_CONF,
	BUDGETS,
	BUDGET_LABELS
} from "../../../constants/constants";
import {
  ROWS_PER_PAGE_BUDGET,
  ROWS_PER_PAGE_OPTIONS_BUDGET,
  ENABLE_SERVER_SIDE_FEATURES_BUDGET,
} from "../../../constants/tableConfigs";
import { jsonToCsv, getErrorMessage } from "../../../helpers/utils";
import { tableCellDisplay, tableCellHeaderDisplay } from "../../../helpers/tableUtils";

import {
	postService,
} from "../../../axios/axios";
import { Operations } from "../../../axios/operations";
import { setBudgetData } from "../../../store/actions/budgetActions";

import moment from 'moment';
import { COMPOSITE_SEARCH_SERVICE } from "../../../constants/baseURLs";
import { formCompositeSearchQuery, formCsFilterObject, formCsPaginationQuery, formCsSortQuery, formRawSearchQuery } from "../../../helpers/csQueryUtils";
import MuiTagItem from "../../../components/Table/MuiDataTable/MuiTagItem/MuiTagItem";
import ExportModal from "../../../components/Modal/ExportModal/ExportModal";
import { getNextTenFiscalYears } from "../../../helpers/dateTimeUtils";

/**
 * styles used in the component.
 */
const useStyles = createUseStyles({
	dataTableWrap: {
		margin: "0",
		'& table > thead > tr > th.MuiTableCell-head': {
			color: '#000000',
			fontWeight: 'bold'
		},
		'& table > thead > tr > th.MuiTableCell-head button': {
			color: '#000000',
			fontWeight: 'bold'
		},
		"& div>table>tbody>tr>td": {
			fontFamily: "Bogle",
			height: "24px",
			fontWeight: "normal",
			lineHeight: "1.33",
		},
		'& table': {
			'& tr': {
				'& th': {
					color: '#2E2F32',
					fontWeight: 'bold',
					fontSize: "12px",
					fontFamily: "Bogle",
				},
			},
		},
	},
	tabContainer: {
		"& nav > ul > li > a": {
			background: "none",
		}
	},
	starColumnHeader: {
		padding: "6px",
	},
	star: {
		padding: "6px",
	},
	handCursor: {
		cursor: "pointer",
	},
	percentChip: {
		width: '60px',
		height: '20px',
		display: 'block',
		margin: '0 auto',
		textAlign: 'center',
		borderRadius: '10px',
		background: '#E3E4E5',
		overflow: 'hidden'
	},
	exportName: {
		marginBottom: 20
	},
	exportNameAs: {
		paddingTop: 20,
		marginBottom: 20
	},
	modalSubText: {
		fontSize: 14,
		fontWeight: 'normal'
	},
	wd80: {
		width: '80px',
		display: "block",
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap'
	},
	wd100: {
		width: '100px',
		display: "block",
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap'
	},
	wd120: {
		width: '120px',
		display: "block",
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap'
	}
});

export const getYesNoTag = (value) => {
  if(value) {
    return <MuiTagItem value='Yes' color="green" variant='secondary' />;
  }
  return <MuiTagItem value='No' color="gray" variant='secondary' />;
}

/**
 * Gets called when user download the export file
 * client side
 * @public
 */
export const exportClientData = (budgets, exportSelection, rowsData, matterNumber) => {
	let csvData = jsonToCsv(budgets);
	if (exportSelection === 'currentSelection') {
		csvData = jsonToCsv(rowsData);
	} else if (exportSelection === 'currentPage') {
		csvData = jsonToCsv(budgets);
	}
	//Download the file as CSV
	const url = window.URL.createObjectURL(
		new Blob([csvData]),
	);
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', `matter-${matterNumber}-budgets.csv`);
	document.body.appendChild(link);
	link.click();
	link.parentNode.removeChild(link);
}


/**
 * Gets called when user download the export file
 *
 * @public
 */
export const getExportedData = (payload, contentType, matterNumber) => {
	const url = window.URL.createObjectURL(
		new Blob([payload], { type: contentType })
	);
	let ext = ".csv";
	if (contentType === "application/octet-stream") {
		ext = ".zip";
	}
	const link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", `matter-${matterNumber}-budgets${ext}`);
	document.body.appendChild(link);
	link.click();
	link.parentNode.removeChild(link);
};


/**
 * Budget component to list the budget under financial component
 */
const MatterBudgetList = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const { currentFinancialTab, currentMatterTab } = props;
	const orgNameCustomBodyRender=(value,tableMeta)=>{
		return <div className={classes.wd100} id={`Mui-orgName-row-${tableMeta?.rowIndex}`}>{value}</div>
	}

	const typeCustomBodyRender=(value,tableMeta)=>{
		return <div className={classes.wd100} id={`Mui-type-row-${tableMeta?.rowIndex}`}>{value}</div>
	}

	const paidPercentCellDisplay = (value, tableMeta) => {
		const paidAm = tableMeta?.rowData[7];
		const totalAm = tableMeta?.rowData[10];
		let vl = '0';
		if (paidAm !== undefined && totalAm !== undefined && paidAm !== 0 && totalAm !==0) {
			vl = (paidAm * 100) / totalAm;
		}
		let barColor = LIGHT_RED;
		if (vl < 30) {
			barColor = LIGHT_GREEN;
		} else if (vl >= 30 && vl < 70) {
			barColor = LIGHT_YELLOW;
		}
		return (
			<div className={`${classes.percentChip}`}>
				<ProgressBar bgColor={barColor} completed={parseInt(value) || parseInt(vl)} />
			</div>
		)
	}

	const COLUMNS = [
		{
			name: "budgetName",
			label: "Budget Name",
			colKey: "budgetName",
			options: {
				sort: true,
				display: true,
				customBodyRender: (value, tableMeta) => tableCellDisplay(`Mui-budgetName-row-${tableMeta?.rowIndex}`, value, classes.wd100),
				customHeadLabelRender: () => tableCellHeaderDisplay(`headcol-0`, 'Budget Name'),
			},
			operation: Operations.LIKE,
      		type: "tag"
		},
		{
			name: "organizationName",
			label: "Organization",
			colKey: "organizationName",
			options: {
				sort: true,
				display: true,
				customBodyRender: orgNameCustomBodyRender,
				customFilterListOptions: { render: (v) => `Organization: ${v}` },
			},
			operation: Operations.LIKE,
      		type: "tag"
		},
		{
			name: "startDate",
			label: "FY",
			colKey: "startDate",
			options: {
				sort: true,
				customBodyRender: (value, tableMeta) => tableCellDisplay(`Mui-startDate-row-${tableMeta?.rowIndex}`, moment(value).format("YYYY")),
				/*customFilterListOptions: {
					render: (v) => {
						return `Min-Max Year: ${v[0]}-${v[1]}`;
					},
					update: (filterList, filterPos, index) => {
						if (filterPos === -1) {
							filterList = [];
						}
						return filterList;
					},
				},
				filter: true,
				filterType: 'custom',
				filterList: [],
				filterOptions: {
					names: [],
					logic(year, filters) {
						if (filters[0] && filters[1]) {
							return year < filters[0] || year > filters[1];
						} else if (filters[0]) {
							return year < filters[0];
						} else if (filters[1]) {
							return year > filters[1];
						}
						return false;
					}
				} This will need in the future*/
			},
		},
		{
			name: "type",
			label: "Type",
			colKey: "type",
			options: {
				sort: true,
				display: true,
				customBodyRender: typeCustomBodyRender,
				customFilterListOptions: { render: (v) => `Type: ${v}` },
			},
			operation: Operations.LIKE,
      		type: "tag"
		},
		{
			name: "status",
			label: "Status",
			colKey: "status",
			options: {
				sort: true,
				display: true,
				customBodyRender: (value, tableMeta) => tableCellDisplay( `Mui-status-row-${tableMeta?.rowIndex}-${value}`, (value?.charAt(0)?.toUpperCase() + value?.toLowerCase()?.slice(1))),
				customFilterListOptions: { render: (v) => `Status: ${v}` },
			},
			operation: Operations.LIKE,
      		type: "tag"
		},
		{
      name: "isActive",
      label: "Active",
      colKey: "isActive",
      options: {
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta) => tableCellDisplay( `Mui-isactive-row-${tableMeta?.rowIndex}-${value}`,getYesNoTag(value)),
        customFilterListOptions: { render: (v) => `Active: ${v}` },
      },
      operation: Operations.EQ,
    },
		{
			name: "amountType",
			label: "Amount Type",
			colKey: "amountType",
			options: {
				display: false
			},
		},
		{
			name: "paidAmount",
			label: "$ Spend",
			colKey: "paidAmount",
			options: {
				sort: true,
				display: true,
				customBodyRender: (value) => tableCellDisplay('paidAmount', value, classes.wd80)
			},
			operation: Operations.EQ,
      		type: "number"
		},
		{
			name: "paidPercent",
			label: "% Spend",
			colKey: "paidPercent",
			options: {
				sort: true,
				customBodyRender: (value, tableMeta) => paidPercentCellDisplay(value, tableMeta)
			},
		},
		{
			name: "balanceAmount",
			label: "$ Balance",
			colKey: "balanceAmount",
			options: {
				sort: true,
				display: true,
				customBodyRender: (value) => tableCellDisplay('balanceAmount', value)
			},
			operation: Operations.EQ,
      		type: "number"
		},
		{
			name: "budgetAmount",
			label: "$ Total",
			colKey: "budgetAmount",
			options: {
				sort: true,
				display: true,
				customBodyRender: (value) => tableCellDisplay('budgeteAmount', value)
			},
			operation: Operations.EQ,
      		type: "number"
		},
		{
			name: "createdBy",
			label: "Created By",
			colKey: "createdBy",
			options: {
				sort: true,
				display: true,
				customBodyRender: (value) => tableCellDisplay('createdBy', value, classes.wd100)
			},
			operation: Operations.LIKE,
      		type: "tag"
		},
		{
			name: "lastUpdatedBy",
			label: "Updated By",
			colKey: "lastUpdatedBy",
			options: {
				sort: true,
				display: true,
				customBodyRender: (value) => tableCellDisplay('lastUpdatedBy', value, classes.wd100)
			},
			operation: Operations.LIKE,
      		type: "tag"
		},
		{
			name: "version",
			label: "Version",
			colKey: "version",
			options: {
				sort: true,
				display: true,
				customBodyRender: (value) => tableCellDisplay('version', value)
			},
			operation: Operations.EQ,
      		type: "number"
		},
		{
			name: "leadAttorneyName",
			label: "Lead Attorney",
			colKey: "leadAttorneyName",
			options: {
				sort: true,
				customBodyRender: (value) => tableCellDisplay('leadAttorneyName', value),
				customFilterListOptions: { render: (v) => `Lead Attorney: ${v}` },
				display: false
			},
		},
		{
			name: "matterNumber",
			label: MATTER_NUMBER,
			colKey: "matterNumber",
			options: {
				sort: true,
				customBodyRender: (value, tableMeta) => tableCellDisplay(`Mui-matterNumber-row-${tableMeta?.rowIndex}-${value}`, value),
				customFilterListOptions: { render: (v) => `${MATTER_NUMBER}: ${v}` },
				display: false
			},
		},
		{
			name: "matterName",
			label: MATTER_NUMBER,
			colKey: "matterName",
			options: {
				sort: true,
				customBodyRender: (value, tableMeta) => tableCellDisplay(`Mui-matterName-row-${tableMeta?.rowIndex}-${value}`, value),
				customFilterListOptions: { render: (v) => `${MATTER_NUMBER}: ${v}` },
				display: false
			},
		},
		{
			name: "leadParalegalName",
			label: "Lead Paralegal",
			colKey: "leadParalegalName",
			options: {
				sort: true,
				customFilterListOptions: { render: (v) => `Lead Paralegal: ${v}` },
				display: false
			},
		},
		{
      name: "fiscalYear",
      label: BUDGET_LABELS.FISCAL_YEAR,
      colKey: "fiscalYear",
      options: {
        customFilterListOptions: { render: (v) => `Time Period: ${v}` },
        display: false
      },
    },
	];

	const dispatch = useDispatch();
	const { budgetData } = useSelector((state) => state?.budget);
	const [budgetColumns, setBudgetColumns] = useState(COLUMNS);
	const [loading, setLoading] = useState(false);
	const [rowsPerPageBudget, setRowsPerPageBudget] = useState(ROWS_PER_PAGE_BUDGET);
	const [searchQuery, setSearchQuery] = useState("");
	const [filterQuery, setFilterQuery] = useState("");
	const [sortQuery, setSortQuery] = useState(CS_DEFAULT_SORT_QUERY);
	const [exportLoading, setExportLoading] = useState(false);
	const [createExportLoading, setCreateExportLoading] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [selectRowsData, setSelectRowsData] = useState([]);
	const [rowsData, setRowsData] = useState([]);
	const { matterDetail } = useSelector((state) => state?.matter);
	const showExportAllOption = budgetData?.count < EXPORT_MAX_LIMIT_CONF.BUDGET;
  const defaultExportOption = showExportAllOption ? 'allBudgets' : 'currentPage';
  const [exportSelection, setExportSelection] = useState(defaultExportOption);
  const [currentPageExport, setCurrentPageExport] = useState(false);
  const tenYears = getNextTenFiscalYears();
	const orgIdentifier = useSelector((state) => state?.organization?.organizationDetailValues?.identifier);

  const defaultQuery = {
	"filters": [
		formCsFilterObject("isDeleted","match",false),
		formCsFilterObject("matterNumber","match",matterDetail?.matter?.matterNumber),
		formCsFilterObject("organizationId","match",orgIdentifier)
	],
    "operation": "AND",
    "properties": null
    }

	const { addSnack } = useSnackbar();

	const FILTER_CONFIGS = [
		{
			colKey: "status",
			type: "checkbox",
			label: "Status",
      options: ["Approved", "Pending", "Rejected",'Expired','Upcoming'],
			defaultOpen: true,
			displayLabel: false,
			accordian: 'Status'
		},
		{
      colKey: "isActive",
      type: "radio",
      label: "Active",
      options: ["Yes", "No"],
      defaultOpen: true,
      displayLabel: false,
      accordian: 'Active',
    },
		{
			colKey: "organizationName",
			type: "orgDropdownAutocomplete",
			label: "Organization Name",
			defaultOpen: true,
			displayLabel: true,
			accordian: 'Organization',
			marginBottom: '10px'
		},
		{
			colKey: "leadAttorneyName",
			type: "peopleDropdownAutocomplete",
			label: "Lead Attorney",
			defaultOpen: true,
			displayLabel: true,
			accordian: 'Organization'
		},
		// {
		//   colKey: "leadParalegalName",
		//   type: "peopleDropdownAutocomplete",
		//   label: "Lead Paralegal",
		//   defaultOpen: true,
		//   displayLabel: true,
		//   accordian: 'Organization'
		// },To-Do
		{
			colKey: "type",
			type: "checkbox",
			label: "Type",
			options: ["Phase", "Total"],
			defaultOpen: true,
			displayLabel: false,
			accordian: 'Type'
		},
		{
      colKey: "fiscalYear",
      type: "dropdownAutocomplete",
      label: "Time Period",
      options: ["LOM", ...tenYears],
      defaultOpen: true,
      displayLabel: false,
      accordian: 'Time Period'
    },
	];

	/**
	 * Function to call Budget Search Service
	 * @param {string} query
	 */
	const getBudgetData = (paginationQuery) => {
		setLoading(true);
		const sortQueryBudget = {...sortQuery};
		if(searchQuery !== null && searchQuery !== undefined && Object.keys(searchQuery).length !== 0 && JSON.stringify(sortQuery) === JSON.stringify(CS_DEFAULT_SORT_QUERY)) {
      sortQueryBudget.sortBy = [CS_SCORE_SORT_OBJ, ...sortQueryBudget?.sortBy];
    }
		const query = formCompositeSearchQuery([CS_SCOPES.BUDGET], filterQuery, searchQuery, sortQueryBudget, paginationQuery, {}, defaultQuery );
		  postService(
				COMPOSITE_SEARCH_SERVICE,
				`/composite-search/v1?returnCsv=false&organizationId=${orgIdentifier}`,
				query
			)
			.then((response) => {
				dispatch(setBudgetData({
					budgets:response?.data?.budget?.data,
					count:response?.data?.budget?.count}));
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				addSnack({
					message: getErrorMessage(error)
				});
			});
	};

	/**
	 * Function to handle row click
	 * @param {object} rowData: row data
	 * @param {object} rowMeta: meta data of selected row
	 */
	const handleRowClick = (rowData, rowMeta) => {
		const { type, id } = budgetData?.budgets[rowMeta?.rowIndex];
		history.push(`/budget/${id}`,
			{
				budgetType: type?.toLowerCase(),
				budgetId: "1",
				fromMatter: true,
				currentMatterTab: currentMatterTab,
				currentFinancialTab: currentFinancialTab,
				matterNumber: matterDetail?.matter?.matterNumber
			})
	}

	/**
	 * Function to validate search query and set query or show no results UI
	 * @param {string} searchText
	 */
	const handleServerSideSearch = (searchText) => {
		const searchQuery = formRawSearchQuery(searchText);
		setSearchQuery(searchQuery);
	};

	/**
	 * Function to set filter query on filter action
	 * @param {string} query
	 */
	const handleServerSideFilter = (query) => {
		setFilterQuery(query);
	};

	/**
	 * Function to call api on pagination action
	 * @param {number} rowsPerPage
	 * @param {number} page
	 */
	const handleServerSidePagination = ({ rowsPerPage, page }) => {
		setRowsPerPageBudget(rowsPerPage);
		const paginationQuery = formCsPaginationQuery(rowsPerPage, (rowsPerPage * page));
    	getBudgetData(paginationQuery);
	};

	/**
	 * Function to call api on sort action
	 * @param {number} rowsPerPage
	 * @param {number} page
	 */
	const handleServerSideSort = ({ name, direction }) => {
		const sortQuery = formCsSortQuery(name === "paidPercent" ? "paidAmount" : name,direction);
    	setSortQuery(sortQuery);
	};

	const budgetListTableCustomOptions = {
		downloadOptions: {
			filename: "budget",
		},
		responsive: "standard",
		rowsPerPage: rowsPerPageBudget,
		rowsPerPageOptions: ROWS_PER_PAGE_OPTIONS_BUDGET,
		filterConfigs: FILTER_CONFIGS,
		serverSide: ENABLE_SERVER_SIDE_FEATURES_BUDGET,
		isBudget: true,
		onServerSideSearch: handleServerSideSearch,
		onServerSideFilter: handleServerSideFilter,
		onServerSidePagination: handleServerSidePagination,
		onServerSideSort: handleServerSideSort,
		totalCount: budgetData?.count
	};

	/**
	 * Cancel Export Modal
	 */
	const onCancelBtnClk = () => {
		setExportLoading(false)
		setModalOpen(false)
	}



	/**
	 * function is to open the export modal to show download options
	 */
	const handleExportBtnClk = () => {
		setExportSelection(defaultExportOption)
		setModalOpen(true);
		setExportLoading(true);
    setCurrentPageExport(true);
	}

	/**
	 * function will use when row is select/deselect
	 */
	const handleRowSelectionChange = (currentSelect, allSelected, selectRowsData) => {
		const bData = budgetData?.budgets;
		const result = allSelected?.map((item) => {
			return bData?.[item?.index]
		});
		setSelectRowsData(selectRowsData);
		setRowsData(result);
	}

	/**
	 * After click on create button download Actions
	 */
	const onClickCreate = () => {
		setCreateExportLoading(true);
		if (ENABLE_SERVER_SIDE_FEATURES_BUDGET === false) {
			const bgData = budgetData && budgetData?.budgets;
			exportClientData(bgData, exportSelection, rowsData, matterDetail?.matter?.matterNumber);
			setModalOpen(false);
			setExportLoading(false);
			setCreateExportLoading(false);
		} else {
			let exportQuery = formCompositeSearchQuery([CS_SCOPES.BUDGET], filterQuery, searchQuery, sortQuery, {}, {getAll: true}, {}, defaultQuery);
			if (exportSelection === 'currentSelection') {
				const result = rowsData?.map(a => a?.id);
				const selectionQuery =
				{
				"filters": [formCsFilterObject("id","in",result)],
				"operation": "AND",
				"properties": null
				}
				exportQuery = formCompositeSearchQuery([CS_SCOPES.BUDGET], {}, {}, {}, {}, {getAll: true}, {}, defaultQuery, selectionQuery);
			} else if (exportSelection === 'currentPage') {
				const result = budgetData?.budgets?.map(a => a.id);
				const selectionQuery =
				{
				"filters": [formCsFilterObject("id","in",result)],
				"operation": "AND",
				"properties": null
				}
				exportQuery = formCompositeSearchQuery([CS_SCOPES.BUDGET], {}, {}, {}, {}, {getAll: true}, {}, defaultQuery, selectionQuery);
			}
			postService(
				COMPOSITE_SEARCH_SERVICE,
				`/composite-search/v1?returnCsv=true&organizationId=${orgIdentifier}`,
				exportQuery
			)
				.then((response) => {
					const data = response?.data;
					const contentType = response?.headers["content-type"];
					getExportedData(data, contentType, matterDetail?.matter?.matterNumber);
					setModalOpen(false);
					setExportLoading(false);
					setCreateExportLoading(false);
				})
				.catch((error) => {
					setModalOpen(false);
					setExportLoading(false);
					setCreateExportLoading(false);
					addSnack({
						message: getErrorMessage(error)
					});
				});
		}
	}

	/**
	 * Function will call when user click and Add New Button
	**/
	const handleAddNew = () => {
		history.push("/budget/0/view", { fromMatter: true });
	}

	/**
	 * To load initial data and handle search/filter/sort query change on Budget datatable
	**/
	useEffect(() => {
		const paginationQuery = {
			"offset": 0,
			"limit": rowsPerPageBudget,
		}
		getBudgetData(paginationQuery);
	}, [searchQuery, filterQuery, sortQuery]);

	const resetSelectedRows = () => {
    setSelectRowsData([]);
    setRowsData([]);
  }

	return (
		<>
			<div className={classes.dataTableWrap}>
				<MuiDataTable
					data={budgetData?.budgets}
					columns={budgetColumns}
					setTableColumns={setBudgetColumns}
					customOptions={budgetListTableCustomOptions}
					selectableRowsType={"multiple"}
					onRowClick={handleRowClick}
					groupFilter={true}
					exportLoading={exportLoading}
					onExportClick={handleExportBtnClk}
					selectedRows={selectRowsData}
					onRowSelection={handleRowSelectionChange}
					onAddNew={handleAddNew}
					loading={loading}
					resetSelectedRows={() => resetSelectedRows()}
          showRowsCounter={false}
				/>
			</div>
			<ExportModal
				title={BUDGETS}
				mode={modalOpen}
				onCancel={onCancelBtnClk}
				onCreate={onClickCreate}
				rowsData={rowsData}
				showExportAllOption={showExportAllOption}
				exportSelection={exportSelection}
				setExportSelection={setExportSelection}
				currentPageExport={currentPageExport}
				setCurrentPageExport={setCurrentPageExport}
				exportLoading={createExportLoading}
			/>
		</>
	);

}

export default MatterBudgetList;

MatterBudgetList.propTypes = {
  /* cancel button click*/
  currentFinancialTab: PropTypes.number,
  /* export submit button click*/
  currentMatterTab: PropTypes.number
};
