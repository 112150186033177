import { createUseStyles } from "react-jss";
import {
  Grid,
  GridColumn,
  Skeleton,
} from "@walmart-web/livingdesign-components";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { amountFormat } from "../../../../../helpers/commonUtils";

/**
 * styles used in the component.
 */
const useStyles = createUseStyles({
  overviewTitleHead: {
    margin: "0",
    paddingBottom: "16px",
    fontSize: "24px",
    lineHeight: "36px",
  },
  overviewTotalBudget: {
    fontSize: "14px",
    color: "#2e2f32;",
  },
  overviewTotal: {
    fontSize: "14px",
    color: "#2e2f32;",
    fontWeight: 'bold'
  },
  pageInfo: {
    paddingBottom: '40px',
    "&:last-child": {
			paddingBottom: 0
		}
  }
});

/**
 * OverviewTotal component used to view overview tab for sub-type "Total"
 *
 */
const OverviewTotal = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const { budgetDetail } = useSelector(state => state?.budget);
  const [budgetTotal, setBudgetTotal] = useState({});

  useEffect(() => {
    if (budgetDetail !== "") {
      setBudgetTotal(budgetDetail?.budgetAmount);
      setLoading(false);
    }
  }, [budgetDetail]);

  return (
    <div data-testid="budget-amount-overview">
      <h3 className={classes.overviewTitleHead} id="total-heading">Total</h3>
      {loading === false ?
        <div>
          <Grid className={classes.pageInfo}>
            <GridColumn sm="1" >
              <span className={classes.overviewTotal}><strong>$ Total</strong></span>
            </GridColumn>
            <GridColumn sm="3">
              <span className={classes.overviewTotalBudget}>{budgetDetail?.budgetAmount ? `$${amountFormat(budgetDetail?.budgetAmount)}` : "--"}</span>
            </GridColumn>
          </Grid>
          <Grid className={classes.pageInfo}>
            <GridColumn sm="1" >
              <span className={classes.overviewTotal}><strong>$ Spend</strong></span>
            </GridColumn>
            <GridColumn sm="3">
              <span className={classes.overviewTotalBudget}>{budgetDetail?.paidAmount ? `$${amountFormat(budgetDetail?.paidAmount)}` : "--"}</span>
            </GridColumn>
          </Grid>
          <Grid className={classes.pageInfo}>
            <GridColumn sm="1" >
              <span className={classes.overviewTotal}><strong>$ Balance</strong></span>
            </GridColumn>
            <GridColumn sm="3">
              <span className={classes.overviewTotalBudget}>{budgetDetail?.balanceAmount ? `$${amountFormat(budgetDetail?.balanceAmount)}` : "--"}</span>
            </GridColumn>
          </Grid>
        </div> :
        <div>
          <Skeleton height="20px" variant="rectangle" width="100%" />
          <Skeleton height="20px" variant="rectangle" width="100%" />
          <Skeleton height="20px" variant="rectangle" width="100%" />
        </div>
      }
    </div>
  )
}

export default OverviewTotal;
